import { FC } from 'react';

export const StoryPostLoader: FC =
	() =>
	{
		return <div
			style={{
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,

				backgroundColor: 'rgba(0, 0, 0, 0.75)',
			}}
		/>;
	};
