import { Avatar, Icon, makeStyles, Tooltip, Typography } from '@material-ui/core';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { NutritionFlagAliases } from '../../../../Api/Product/NutritionFlag';
import { NutritionInformation } from '../../../../Api/Product/NutritionInformation';
import { ProductFee } from '../../../../Api/Product/ProductFee';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';
import { getParagraphStylesWithoutTopAndBottomMargin } from '../../../../Util/Html/getParagraphStylesWithoutTopAndBottomMargin';
import { StoryButtons } from '../../../story/buttons/StoryButtons';
import { AnnouncementPill } from '../../../UI/AnnouncementPill';
import { NutritionFlagBadge } from '../../Profile/NutritionFlagBadge';
import { nutritionFlagTranslation } from '../../Profile/Profile';
import { getAllergenImage } from './Allergen/Allergen';
import { ProductStore } from './ProductStore';

const useStoriesStyles = makeStyles(theme => ({
    buttons: {
        paddingBottom: theme.spacing(2),
    }
}))

const useStyles =
    makeStyles(
        () => ({
            description: {
                ...getParagraphStylesWithoutTopAndBottomMargin(),
            }
        })
    );

interface ProductInformationProps
{
    store: ProductStore;
    hideTitle?: boolean;
}

export const ProductInformation: FC<ProductInformationProps> =
    (
        {
            store,
            hideTitle,
        }
    ) =>
    {
        return <>
            <Stories store={store} />
            <Announcements store={store} />
            <Description
                store={store}
                hideTitle={hideTitle}
            />
            <Allergens store={store} />
            <NutritionBadges store={store} />
            <NutritionTable store={store} />
            <ProductFees store={store} />
        </>;
    };

const Description: FC<ProductInformationProps> =
    (
        {
            store,
            hideTitle,
        }
    ) =>
    {
        const classes = useStyles();
        const sanitizedHtmlDescription = useObserver(() => store.sanitizedHtmlDescription);
        const isTitleHidden = useObserver(() => (store.product.detailTitleOverPicture ?? false) || hideTitle);
        const productName = useObserver(() => store.product.name);
        const productDescriptionLines = useObserver(() => store.product.descriptionLines);

        if (sanitizedHtmlDescription)
        {
            return <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                    __html: sanitizedHtmlDescription,
                }}
                className={classes.description}
            />;
        }
        else
        {
            return <>
                {
                    productDescriptionLines
                        ?.map((line, idx) =>
                            <div
                                key={idx}
                                style={(!isTitleHidden && idx === 0) ? {
                                    pageBreakInside: 'avoid',
                                } : {}}
                            >
                                {
                                    !isTitleHidden && idx === 0 &&
                                    <Typography
                                        variant="h4"
                                        style={{
                                            bottom: 0,
                                            color: 'black',
                                            margin: '0px 0px 13px -2px',
                                        }}
                                    >
                                        {productName}
                                    </Typography>
                                }
                                <Typography
                                    variant="body1"
                                    color="textPrimary"
                                >
                                    {
                                        line.length === 0 && idx !== productDescriptionLines.length - 1
                                            ?
                                            <span>&nbsp;</span>
                                            :
                                            line
                                    }
                                </Typography>
                            </div>)
                }
            </>;
        }
    };

const Allergens: FC<ProductInformationProps> =
    ({
        store,
    }) =>
    {
        const translate = useTranslate();

        const allergens = useObserver(() => store.product.allergens);
        const allergenOrNutritionFlagToShowTooltipFor = useObserver(() => store.allergenOrNutritionFlagToShowTooltipFor);
        const askStaffForAllergenInfo = useObserver(() => store.product.askStaffForAllergenInfo);

        if (allergens.length > 0 || askStaffForAllergenInfo)
        {
            return <div
                style={{
                    breakInside: 'avoid',
                }}
            >
                <Typography
                    variant="h6"
                    style={{
                        marginTop: 20,
                        marginBottom: 4,
                    }}
                >
                    {translate('Allergens')}
                </Typography>
                {
                    allergens.map(
                        (allergen, index) =>
                            <Tooltip
                                title={translate(`Allergens-${allergen.charAt(0).toUpperCase().concat(allergen.substr(1))}`)}
                                key={index}
                                open={allergenOrNutritionFlagToShowTooltipFor === allergen}
                            >
                                <div
                                    style={{
                                        display: 'inline-block',
                                        marginRight: 7,
                                    }}
                                >
                                    {/* Put text in DOM so allergen badge is findable in tests */}
                                    <div
                                        style={{
                                            display: 'none',
                                        }}
                                    >
                                        {allergen}
                                    </div>
                                    <Avatar
                                        onClick={() => store.onClickAllergenOrNutritionFlag(allergen)}
                                        src={getAllergenImage(allergen)}
                                    />
                                </div>
                            </Tooltip>,
                    )
                }
                {
                    askStaffForAllergenInfo &&
                    <AnnouncementPill
                        content={translate('Allergens-AskStaff')}
                        severity="warning"
                        style={{
                            marginTop: 8,
                        }}
                    />
                }
            </div>;
        }
        else
        {
            return null;
        }
    };

const NutritionBadges: FC<ProductInformationProps> =
    (
        {
            store,
        }
    ) =>
    {
        return useObserver(() => {
            if (!store.product.nutritionInformation)
                return null;
            return <div
                style={{
                    breakInside: 'avoid',
                    marginBottom: 20,
                }}
            >
                <Typography
                    variant="h6"
                    style={{
                        marginTop: 20,
                        marginBottom: 4,
                    }}
                >
                    {store.bridge.localizer.translate('Product-NutritionInformation')}
                </Typography>
                {/*{*/}
                {/*    store.product.nutritionInformation.nutritionFlags.length > 0 &&*/}
                {/*    store.product*/}
                {/*        .nutritionInformation*/}
                {/*        .nutritionFlags*/}
                {/*        .map(*/}
                {/*        (nutritionFlag, index) =>*/}
                {/*            <Tooltip*/}
                {/*                title={store.bridge.localizer.translate(nutritionFlagTranslation(nutritionFlag))}*/}
                {/*                key={index}*/}
                {/*                open={store.allergenOrNutritionFlagToShowTooltipFor === nutritionFlag}*/}
                {/*            >*/}
                {/*                <div*/}
                {/*                    style={{*/}
                {/*                        display: 'inline-block',*/}
                {/*                        marginRight: 7,*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    /!* Put text in DOM so nutritionFlag badge is findable in tests *!/*/}
                {/*                    <div*/}
                {/*                        style={{*/}
                {/*                            display: 'none',*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        {nutritionFlag}*/}
                {/*                    </div>*/}
                {/*                    {nutritionIcon(*/}
                {/*                        nutritionFlag,*/}
                {/*                        undefined,*/}
                {/*                        () => store.onClickAllergenOrNutritionFlag(nutritionFlag)*/}
                {/*                    )}*/}
                {/*                </div>*/}
                {/*            </Tooltip>,*/}
                {/*    )*/}
                {/*}*/}
                <div
                    style={{
                        columnWidth: 200,
                        columnFill: 'balance',
                    }}
                >
                    <table
                        style={{
                            borderSpacing: 0,
                        }}
                    >
                        <tbody>
                        {
                            store.product.nutritionInformation
                                .nutritionFlags
                                .map(flag => NutritionFlagAliases.get(flag) ?? flag)
                                .map((nutritionFlag, idx) =>
                                    <tr
                                        key={idx}
                                        style={{
                                            breakInside: 'avoid',
                                        }}
                                    >
                                        <td
                                            style={{
                                                display: 'flex',
                                                paddingTop: 4,
                                                paddingBottom: 4,
                                                paddingRight: 8,
                                            }}
                                        >
                                            <NutritionFlagBadge
                                                flag={nutritionFlag}
                                                onClick={() => store.onClickAllergenOrNutritionFlag(nutritionFlag)}
                                            />
                                        </td>
                                        <td
                                            style={{
                                                paddingLeft: 0,
                                                paddingRight: 12,
                                            }}
                                        >
                                            <Typography
                                                color="textPrimary"
                                            >
                                                {store.bridge.localizer.translate(nutritionFlagTranslation(nutritionFlag))}
                                            </Typography>
                                        </td>
                                    </tr>)
                        }
                        </tbody>
                    </table>
                </div>
            </div>;
        });
    };

const NutritionTable: FC<ProductInformationProps> =
    (
        {
            store,
        }
    ) =>
    {
        return useObserver(() => {
            if (!store.product.nutritionInformation)
                return null;
            return <div
                style={{
                    breakInside: 'avoid',
                }}
            >
                <div
                    style={{
                        columnWidth: 200,
                        columnFill: 'balance',
                    }}
                >
                    <table
                        style={{
                            borderSpacing: 0,
                        }}
                    >
                        <tbody>
                        {
                            Object.keys(store.product.nutritionInformation.nutritionTableRows)
                                .map(key => ({key: key, value: (store.product.nutritionInformation.nutritionTableRows)[key]}))
                                .filter(kv => kv.value != null)
                                .map((kv, idx) =>
                                    <tr
                                        key={idx}
                                    >
                                        <td
                                            style={{
                                                paddingLeft: 0,
                                                paddingRight: 12,
                                            }}
                                        >
                                            <Typography
                                                color="textPrimary"
                                            >
                                                {store.bridge.localizer.translate(`Product-Nutrition-${kv.key.charAt(0).toUpperCase().concat(kv.key.substr(1))}`)}
                                            </Typography>
                                        </td>
                                        <td>
                                            {
                                                typeof kv.value === 'boolean' &&
                                                <Icon>
                                                    {
                                                        kv.value
                                                            ?
                                                            'check'
                                                            :
                                                            'close'
                                                    }
                                                </Icon>
                                            }
                                            {
                                                (typeof kv.value === 'number' || typeof kv.value === 'string') &&
                                                <Typography
                                                    color="textSecondary"
                                                >
                                                    {kv.value}{store.product.nutritionInformation.getUnit(kv.key as keyof NutritionInformation)}
                                                </Typography>
                                            }
                                        </td>
                                    </tr>)
                        }
                        </tbody>
                    </table>
                </div>
            </div>;
        });
    };

const ProductFees: FC<ProductInformationProps> =
    (
        {
            store,
        }
    ) =>
    {
        const translate = useTranslate();

        const activeFees = useObserver(() => store.currentlyActiveProductFees);

        if (activeFees.length === 0)
        {
            return null;
        }
        else
        {
            return <>
                <Typography
                    style={{
                        marginTop: 20,
                        marginBottom: 4,
                    }}
                    variant="h6"
                >
                    {translate('Product-Fees')}
                </Typography>
                {
                    activeFees.map(
                        fee =>
                            <Fragment key={fee.id}>
                                <div
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            flex: '1 1 auto',
                                        }}
                                    >
                                        {fee.name}
                                        {
                                            fee.applyOncePerOrder &&
                                            <sup>
                                                *
                                            </sup>
                                        }
                                    </Typography>
                                    <Typography>
                                        {getFeeText(store, fee)}
                                    </Typography>
                                </div>
                            </Fragment>,
                    )
                }
                {
                    activeFees.some(fee => fee.applyOncePerOrder) &&
                    <Typography
                        color="textSecondary"
                    >
                        <sup>*</sup>
                        {translate('Product-Fees-AppliedOnce')}
                    </Typography>
                }
            </>;
        }
    };

const Stories: FC<ProductInformationProps> =
    ({
        store,
    }) =>
    {
        const stories = useObserver(() => store.stories);
        const hasConfigurationStore = useObserver(() => store.configurationStore !== undefined);
        const menuStore = useObserver(() => store.businessStore.menuStore);

        const classes = useStoriesStyles();

        if (hasConfigurationStore)
        {
            return null;
        }
        else
        {
            return <StoryButtons
                className={classes.buttons}
                stories={stories}
                menuStore={menuStore}
            />;
        }
    };

const Announcements: FC<ProductInformationProps> =
    (
        {
            store,
        }
    ) =>
    {
        const announcements = useObserver(() => store.announcements);
        const hasConfigurationStore = useObserver(() => store.configurationStore !== undefined);

        if (hasConfigurationStore)
            return null;
        return <>
            {
                announcements.map(
                    (announcement, idx) =>
                        <AnnouncementPill
                            content={announcement.content}
                            action={announcement.action}
                            linkUrl={announcement.linkUrl}
                            severity={announcement.type}
                            key={idx}
                            style={{
                                marginBottom: idx === announcements.length - 1 ? 8 : 4,
                            }}
                        />)
            }
        </>;
    };

function getFeeText(store: ProductStore, fee: ProductFee)
{
    let feeText = '';

    if (fee)
    {
        if (fee.feeAmount)
        {
            const feeValueString: string | null | undefined = (() =>
            {
                if (store.place.productCurrencySymbolsHidden)
                    return store.bridge.localizer.formatCurrencyWithoutSymbol(new Decimal(fee.feeAmount), store.business.productCurrencyCode);
                else
                    return store.bridge.localizer.formatCurrency(new Decimal(fee.feeAmount), store.business.productCurrencyCode);
            })();

            feeText = `(${feeValueString}`;

            if (fee.feePercentage)
            {
                feeText = feeText + ` + ${fee.feePercentage * 100}%`;
            }

            feeText = feeText + ')';
        }
        else
        {
            if (fee.feePercentage)
            {
                feeText = `(${fee.feePercentage * 100}%)`;
            }
        }
    }

    return feeText;
}
