import { StoryLocation } from '../../../../../Api/v3/model/story/location/StoryLocation';

export function flattenStoryLocation(value: StoryLocation): StoryLocation[]
{
	if (value.type === 'Composite')
	{
		return value
			.locations
			.flatMap(flattenStoryLocation);
	}
	else
	{
		return [value];
	}
}
