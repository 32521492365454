import { Fetch } from '../fetch/Fetch';
import { HateoasCollection, HateoasCollectionElement } from '../model/hateoas/HateoasCollection';
import { iterateOverHateoasCollection } from './iterateOverHateoasCollection';

export async function collectHateoasCollection<D extends HateoasCollectionElement, K extends string>(
	fetch: Fetch,
	producer: () => Promise<HateoasCollection<D, K>>,
	collectionName: K
): Promise<D[]>
{
	const collection: D[] = [];

	await iterateOverHateoasCollection(
		fetch,
		producer,
		collectionName,
		async elements =>
		{
			collection.push(
				...elements
			);
		}
	);

	return collection;
}
