import { useObserver } from 'mobx-react-lite';
import { CSSProperties, useContext, useMemo } from 'react';
import { TextElement } from '../../../../../../../Api/v3/model/story_post/content/element/TextElement';
import { isBusinessFont } from '../../../../../../../Api/v3/model/story_post/content/shared/font/Font';
import { BusinessContextRef } from '../../../../../../Page/Business/BusinessContext';
import { StoryPostContext } from '../../../../../context/StoryPostContext';
import { getLinearGradientCss } from '../../../api/getLinearGradientCss';
import { getSolidColorCss } from '../../../api/getSolidColorCss';

const PaddingXFactor = 0.6;
const PaddingYFactor = 0.2;

export function useTextElementStyleProps(
	element: Pick<TextElement, 'textColor' | 'textSize' | 'textFormat' | 'textAlignment' | 'textFont'>,
	postHeight: number,
): CSSProperties
{
	const {businessStore} = useContext(BusinessContextRef);
	const {didLoad} = useContext(StoryPostContext);

	const getLoadedFont = useObserver(() => businessStore.fontService.getLoadedFont);

	const font = useMemo(
		() =>
			didLoad && isBusinessFont(element.textFont)
				? getLoadedFont(element.textFont.fontId)
				: undefined,
		[didLoad, element.textFont, getLoadedFont],
	);

	return {
		paddingTop: `${element.textSize * PaddingYFactor * postHeight}px`,
		paddingRight: `${element.textSize * PaddingXFactor * postHeight}px`,
		paddingBottom: `${element.textSize * PaddingYFactor * postHeight}px`,
		paddingLeft: `${element.textSize * PaddingXFactor * postHeight}px`,
		fontSize: `${element.textSize * postHeight}px`,
		...font?.family
			? {
				fontFamily: font.family,
			}
			: {},
		whiteSpace: 'pre',
		...element.textFormat.includes('Bold')
			? {
				fontWeight: 600,
			}
			: {},
		...element.textFormat.includes('Italic')
			? {
				fontStyle: 'italic',
			}
			: {},
		textAlign: element.textAlignment.toLowerCase() as ('left' | 'center' | 'right'),
		...element.textColor.type === 'Solid'
			? {
				color: getSolidColorCss(element.textColor),
			}
			: {},
		...element.textColor.type === 'LinearGradient'
			? {
				backgroundImage: getLinearGradientCss(element.textColor),
				backgroundClip: 'text',
				color: 'transparent',
			}
			: {},
	};
}
