import { createContext } from 'react';

interface StoryPlayerContextValue
{
	openPostIdx: number;
	onChangeOpenPostIdx: (value: number) => void;
	isPlaying: boolean;
	onChangeIsPlaying: (value: boolean) => void;
	didLoad: (postIdx: number) => boolean;
}

export const StoryPlayerContext = createContext<StoryPlayerContextValue>({
	openPostIdx: 0,
	onChangeOpenPostIdx: Promise.resolve,
	isPlaying: true,
	onChangeIsPlaying: () => {},
	didLoad: () => true,
});
