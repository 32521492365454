import { createContext } from 'react';
import { HateoasStoryPost } from '../../../Api/v3/model/story_post/HateoasStoryPost';

interface StoryPostContextValue
{
	post: HateoasStoryPost;
	postIdx: number;
	active: boolean;
	didLoad: boolean;
}

export const StoryPostContext = createContext<StoryPostContextValue>(undefined!);
