import { FC, useCallback, useState } from 'react';
import { MenuStore } from '../../Page/Business/Menu/MenuStore';
import { StoryWithPosts } from '../../Page/Business/StoriesPlayer/Model/StoryWithPosts';
import { StoriesPlayerContextProvider } from '../player/context/StoriesPlayerContextProvider';
import { StoriesPlayer } from '../player/StoriesPlayer';
import { StoryButton } from './button/StoryButton';

interface StoryButtonsProps
{
	className?: string;
	stories: StoryWithPosts[];
	menuStore: MenuStore;
}

export const StoryButtons: FC<StoryButtonsProps> =
	({
		className,
		stories,
		menuStore,
	}) =>
	{
		const [openStoryIdx, setOpenStoryIdx] = useState<number | undefined>(undefined);

		const handleClose = useCallback(
			() =>
				setOpenStoryIdx(undefined),
			[],
		);

		if (stories.length > 0)
		{
			return <div
				className={className}
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					flexWrap: 'wrap',
					gap: 12,
				}}
			>
				{
					stories.map(
						(story, idx) =>
							<StoryButton
								key={story.story.id}
								story={story}
								onClick={
									() =>
										setOpenStoryIdx(idx)
								}
							/>,
					)
				}
				{
					openStoryIdx !== undefined &&
					<StoriesPlayerContextProvider
						key={openStoryIdx}
						stories={stories}
						initialOpenStoryIdx={openStoryIdx}
						onClose={handleClose}
					>
						<StoriesPlayer
							menuStore={menuStore}
						/>
					</StoriesPlayerContextProvider>
				}
			</div>;
		}
		else
		{
			return null;
		}
	};
