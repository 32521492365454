import { createContext } from 'react';
import { StoryWithPosts } from '../../../Page/Business/StoriesPlayer/Model/StoryWithPosts';

interface StoriesPlayerContextValue
{
	stories: StoryWithPosts[];
	isPaused: boolean;
	onChangeIsPaused: (value: boolean) => void;
	openStoryIdx: number;
	onChangeOpenStoryIdx: (value: number) => void;
}

export const StoriesPlayerContext = createContext<StoriesPlayerContextValue>(undefined!);
