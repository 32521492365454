import { FC } from 'react';
import { LinearGradientColorFill } from '../../../../../../Api/v3/model/story_post/content/shared/color_fill/LinearGradientColorFill';
import { getLinearGradientCss } from '../../api/getLinearGradientCss';

interface LinearGradientBackgroundViewerProps
{
	backgroundColor: LinearGradientColorFill;
}

export const LinearGradientBackgroundViewer: FC<LinearGradientBackgroundViewerProps> =
	({
		backgroundColor,
	}) =>
	{
		return <div
			style={{
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,

				background: getLinearGradientCss(backgroundColor),
			}}
		/>;
	};
