import { Grid, Grow, makeStyles, Typography } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { useFetch } from '../../../../Api/v3/fetch/useFetch';
import { WebClient } from '../../../../Bridge/Client/WebClient';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';

// @ts-ignore
import TikkieLogo from '../../../../Resources/payment_sharing_provider/tikkie/logo.png';
import { EntranceContextRef } from '../../Entrance/EntranceContext';
import { HistoricalOrder } from '../Order/HistoricalOrder';
import { OrderAction } from '../Order/OrderAction';
import { canClaimLoyaltyPoints, claimLoyaltyPoints } from './claimLoyaltyPoints';
import { HistoryOpenBillSection } from './HistoryOpenBillSection';
import { HistorySectionHeader } from './HistorySectionHeader';
import { HistoryStore } from './HistoryStore';
import { PaymentInfoDialog } from './PaymentInfo/PaymentInfoDialog';
import { PaymentSharingDialog } from './share_payment_dialog/PaymentSharingDialog';
import { canShareOrderPaymentWithTikkie } from './shareOrderPaymentWithTikkie';
import { useShowHistoryOpenBillSection } from './useShowHistoryOpenBillSection';

const useStyles = makeStyles(theme => ({
	tikkieButton: {
		backgroundColor: '#373772 !important',
		'& p': {
			color: `${theme.palette.common.white} !important`,
		},
		'&:hover': {
			backgroundColor: '#8b8bc7 !important',
		}
	},
}))

const ORDER_AGAIN_DELAY_MS = 1000 * 60 * 40;

interface HistoryProps
{
	store: HistoryStore;
}

export const History: FC<HistoryProps> =
	(
		{
			store,
		},
	) =>
	{
		const classes = useStyles();

		const {client, clockService, localizer, orderService} = useContext(EntranceContextRef);
		const translate = useTranslate();
		const fetch = useFetch();

		const [paymentInfoOrder, setPaymentInfoOrder] = useState<OrderDescriptor | undefined>();
		const [orderToSharePaymentOf, setOrderToSharePaymentOf] = useState<OrderDescriptor | undefined>();

		const onPaymentInfoClose = useCallback(() => setPaymentInfoOrder(undefined), []);
		const onEndSharePayment = useCallback(() => setOrderToSharePaymentOf(undefined), []);

		const showHistoryOpenBillSection = useShowHistoryOpenBillSection();

		const canOrderAgain = useObserver(() => store.canOrderAgain);
		const orderAgain = useObserver(() => store.orderAgain);
		const shareOrder = useObserver(() => store.shareOrder);
		const currentMinuteMillis = useObserver(() => clockService.currentMinuteMillis);
		const openOnSubmitOrderStoriesIfNecessary = useObserver(() => store.openOnSubmitOrderStoriesIfNecessary);

		const isMobileOrTablet = useObserver(
			() =>
				client instanceof WebClient
					? client.isMobileOrTablet()
					: true,
		);

		const orderAgainAction = useMemo(() =>
		{
			return new OrderAction(
				'OrderAgain',
				() => translate('Client-OrderHistory-Order-Again'),
				order => canOrderAgain(order),
				order => orderAgain(order),
				'major',
			);
		}, [canOrderAgain, orderAgain, translate]);

		const claimLoyaltyPointsAction = useMemo(() =>
		{
			return new OrderAction(
				'ClaimLoyaltyPoints',
				() => translate('Client-OrderHistory-Claim-Loyalty-Points'),
				canClaimLoyaltyPoints,
				claimLoyaltyPoints,
				'major',
			);
		}, [translate]);

		const shareAction = useMemo(() =>
		{
			return new OrderAction(
				'Share',
				() => translate('Generic-Share'),
				() => true,
				shareOrder,
				'minor',
				ShareIcon,
			);
		}, [shareOrder, translate]);

		const sharePaymentWithTikkieAction = useMemo(() =>
		{
			if (isMobileOrTablet)
				return new OrderAction(
					'ShareOrderPaymentWithTikkie',
					() => translate('Client-OrderHistory-Share-Order-Send-Tikkie'),
					canShareOrderPaymentWithTikkie,
					setOrderToSharePaymentOf,
					'minor',
					TikkieLogo,
					classes.tikkieButton,
					'contained',
				);
		}, [classes.tikkieButton, isMobileOrTablet, translate]);

		const getOrderActions = useCallback((order: OrderDescriptor) =>
		{
			const actions: OrderAction[] = [];

			const orderTime = order.dateScheduled ?? order.dateOrdered;

			const orderIsFailed = order.state === 'voided';

			const allowedToOrderAgain = orderTime === undefined
				? orderIsFailed
				: currentMinuteMillis > (new Date(orderTime).getTime() + ORDER_AGAIN_DELAY_MS) || orderIsFailed;

			if (allowedToOrderAgain)
				actions.push(orderAgainAction);

			actions.push(claimLoyaltyPointsAction);
			actions.push(shareAction);

			if (sharePaymentWithTikkieAction !== undefined)
				actions.push(sharePaymentWithTikkieAction);

			return actions;
		}, [claimLoyaltyPointsAction, currentMinuteMillis, orderAgainAction, shareAction, sharePaymentWithTikkieAction]);

		useEffect(
			() =>
			{
				// noinspection JSIgnoredPromiseFromCall
				openOnSubmitOrderStoriesIfNecessary(fetch);
			},
			[fetch, openOnSubmitOrderStoriesIfNecessary],
		);

		return useObserver(() => (
			<>
				<PaymentInfoDialog onClose={onPaymentInfoClose} order={paymentInfoOrder} />
				<Grid container spacing={2}>
					{showHistoryOpenBillSection && <HistoryOpenBillSection />}
					{showHistoryOpenBillSection && <HistorySectionHeader translationKey="Orders" />}
					{
						orderService.orders.length === 0 &&
						<Grid item xs={12}>
							<Typography
							>
								{localizer.translate('Client-OrderHistory-NoOrders')}
							</Typography>
						</Grid>
					}
					{
						orderService.orders.map(order =>
							{
								return <Grow
									key={order.id}
									in
								>
									<Grid
										item
										xs={12}
									>
										<HistoricalOrder
											actions={getOrderActions(order)}
											onPaymentClick={setPaymentInfoOrder}
											order={order}
										/>
									</Grid>
								</Grow>;
							},
						)
					}
				</Grid>
				<PaymentSharingDialog
					onClose={onEndSharePayment}
					order={orderToSharePaymentOf}
				/>
			</>
		));
	};
