export class HttpError extends Error
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	public readonly name = 'HttpError';

	public readonly method: string;

	public readonly url: string;

	public readonly status: number;

	public readonly body: Record<string, unknown>;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		method: string,
		url: string,
		status: number,
		body: Record<string, unknown>
	)
	{
		super(`Received ${status} from ${method} ${url}`);

		this.method = method;
		this.url = url;
		this.status = status;
		this.body = body;
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	public get is4xxClientError(): boolean
	{
		return this.status >= 400 && this.status < 500;
	}
}
