import { Box } from '@material-ui/core';
import { CSSProperties, FC, PropsWithChildren } from 'react';

interface CubeFaceProps
{
	rotation: number;
	width: number;
	render: boolean;
	style?: CSSProperties;
}

export const CubeFace: FC<PropsWithChildren<CubeFaceProps>> =
	({
		rotation,
		width,
		render,
		style,
		children,
	}) =>
	{
		return <Box
			style={{
				display: render
					? undefined
					: 'none',
				position: 'absolute',
				top: 0,
				left: 0,
				width: 'inherit',
				height: 'inherit',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center center',
				borderRadius: '8px',
				transform: `rotateY(${rotation}deg) translateZ(${width / 2}px)`,
				...(style ?? {}),
			}}
		>
			{children}
		</Box>;
	};
