import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useFetch } from '../../../../Api/v3/fetch/useFetch';
import { PaymentDialog } from '../../../UI/payment/dialog/PaymentDialog';
import { Menu } from './Menu';
import { MenuStore } from './MenuStore';

interface MenuWrapperProps
{
	onStartPayingBill: () => void;
	openNavigationMenu?: () => void;
	store: MenuStore;
}

export const MenuWrapper: FC<MenuWrapperProps> =
	(
		{
			onStartPayingBill,
			openNavigationMenu,
			store,
		},
	) =>
	{
		const fetch = useFetch();

		const openHistory = useObserver(() => store.openHistory);
		const openStore = useObserver(() => store.openSubMenu ?? store);
		const loadStoriesIfRequired = useObserver(() => store.businessStore.loadStoriesIfRequired);

		useEffect(
			() =>
			{
				// noinspection JSIgnoredPromiseFromCall
				loadStoriesIfRequired(fetch);
			},
			[fetch, loadStoriesIfRequired],
		);

		return <>
			<Menu
				onStartPayingBill={onStartPayingBill}
				openNavigationMenu={openNavigationMenu}
				store={openStore}
			/>
			<PaymentDialog
				onClose={openHistory}
			/>
		</>;
	};