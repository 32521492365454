import { FC } from 'react';
import { Element } from '../../../../Api/v3/model/story_post/content/element/Element';
import { ActionContext } from './action/ActionContext';
import { ElementViewer } from './element/ElementViewer';

interface ElementViewerProps
{
	elements: Element[];
	actionContext: ActionContext;
}

export const ElementsViewer: FC<ElementViewerProps> =
	({
		elements,
		actionContext,
	}) =>
	{
		return <>
			{
				elements.map(element =>
					<ElementViewer
						key={element.id}
						element={element}
						actionContext={actionContext}
					/>,
				)
			}
		</>;
	};
