import { Fetch, RequestInitWithParams } from './Fetch';
import { fetchJson } from './fetchJson';

export function postJson<T, B extends object = object>(
	fetch: Fetch,
	input: RequestInfo,
	body: B,
	init?: RequestInitWithParams
): Promise<T>
{
	return fetchJson(
		fetch,
		input,
		{
			...init,
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body)
		}
	);
}
