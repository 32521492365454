import { useObserver } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { isTrueAtInCache } from '../../../../../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import { useRootContext } from '../../../../../../RootContext';
import { useCurrentPlaceService } from '../../../../../current-place-service/CurrentPlaceService';
import { useIsRoutingByIdLive } from '../../../../../routing-state-provider/useIsRoutingByIdLive';
import { BusinessContextRef } from '../../../BusinessContext';

export function useShowRequestBillButton()
{
	const {businessStore} = useContext(BusinessContextRef);
	const {place} = useCurrentPlaceService();
	const {clockService} = useRootContext(true);
	const currentMinute = useObserver(() => clockService.currentMinute);

	const timeScheduleById = useObserver(() => businessStore.timeScheduleById);
	const isOrderBillSupported = useObserver(() => place.isOrderBillSupported);
	const requestBillRoutingId = useObserver(() => place?.requestBillRoutingId);
	const isRoutingLive = useIsRoutingByIdLive(requestBillRoutingId);
	const isRoutingStatusIgnored = useObserver(() => place?.routingStatusIgnored);
	const hasNoRoutingOrRoutingIsLive = requestBillRoutingId === undefined || isRoutingLive || isRoutingStatusIgnored;

	const isVisibleInTimeSchedule = useMemo(
		() =>
		{
			if (place?.requestBillTimeScheduleId === undefined)
			{
				return true;
			}
			else
			{
				const timeSeries = timeScheduleById.get(place.requestBillTimeScheduleId)?.booleanTimeSeries;

				if (timeSeries === undefined)
				{
					return true;
				}
				else
				{
					return isTrueAtInCache(timeSeries, currentMinute);
				}
			}
		},
		[currentMinute, place.requestBillTimeScheduleId, timeScheduleById],
	);

	return isOrderBillSupported && hasNoRoutingOrRoutingIsLive && isVisibleInTimeSchedule;
}