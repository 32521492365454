import { Button } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, ReactNode } from 'react';
import { useBrandingService } from '../../../../branding-service/BrandingServiceProvider';

interface TopContentButtonProps
{
	icon: ReactNode;
	label: string;
	onClick: () => void;
}

export const TopContentButton: FC<TopContentButtonProps> =
	({
		icon,
		label,
		onClick,
	}) =>
	{
		const brandingService = useBrandingService();
		const textColor = useObserver(() => brandingService.tintContrastTextColor.css);
		const [headLabel, ...remainderLabels] = label.split(' ');

		return <Button
			color="primary"
			variant="contained"
			style={{
				textTransform: 'none',
				fontSize: '1rem',
				padding: '16px 16px',
				lineHeight: 1.4,
				textAlign: 'left',
				height: '100%',
			}}
			startIcon={icon}
			fullWidth
			onClick={onClick}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontWeight: 300,
					paddingLeft: 8,
					color: textColor,
				}}
			>
				<div>
					{headLabel}
				</div>
				{
					remainderLabels.length > 0 &&
					<div
						style={{
							fontWeight: 600,
						}}
					>
						{remainderLabels.join(' ')}
					</div>
				}
			</div>
		</Button>;
	};
