import { Box, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';
import { useWebClient } from '../../../../Bridge/Client/WebClientProvider';
import { useIsKioskMode } from '../../../../Service/KioskService/Api/useIsKioskMode';
import { useCurrentPlaceService } from '../../../current-place-service/CurrentPlaceService';
import { PlacePaymentContextProvider } from '../../../UI/payment/composite/place/PlacePaymentContextProvider';
import { ProcessingOrderContextProvider } from '../../../UI/processing_order_context/ProcessingOrderContext';
import { ValidationContextProvider } from '../../../UI/validation/context/ValidationContext';
import { EntranceContextRef } from '../../Entrance/EntranceContext';
import { BusinessContextRef, useCurrentOrderService } from '../BusinessContext';
import { CloudyScrollContainer } from './CloudyScrollContainer';
import { OrderBuilderStore } from './OrderBuilderStore';
import { AgeVerificationOrderBuilderSegment } from './segments/age_verification/AgeVerificationOrderBuilderSegment';
import { AnnouncementOrderBuilderSegments } from './segments/announcements/AnnouncementOrderBuilderSegments';
import { CommentOrderBuilderSegment } from './segments/comment/CommentOrderBuilderSegment';
import { ComoRewardOrderBuilderSegment } from './segments/como_reward/ComoRewardOrderBuilderSegment';
import { CouponOrderBuilderSegment } from './segments/coupon/CouponOrderBuilderSegment';
import { CustomerCardOrderBuilderSegment } from './segments/customer_card/CustomerCardOrderBuilderSegment';
import { LegalConsentOrderBuilderSegment } from './segments/legalconsent/LegalConsentOrderBuilderSegment';
import { OrderButtonOrderBuilderSegment } from './segments/orderbutton/OrderButtonOrderBuilderSegment';
import { PasswordOrderBuilderSegment } from './segments/pasword/PasswordOrderBuilderSegment';
import { PaymentOrderBuilderSegment } from './segments/payment/PaymentOrderBuilderSegment';
import { PeerShoppingCarts } from './segments/peer_shopping_carts/PeerShoppingCarts';
import { ProductRecommendationOrderBuilderSegment } from './segments/product_recommendations/ProductRecommendationOrderBuilderSegment';
import { SchedulerOrderBuilderSegment } from './segments/scheduler/SchedulerOrderBuilderSegment';
import { ServiceOrderBuilderSegment } from './segments/service/ServiceOrderBuilderSegment';
import { ShoppingCartOrderBuilderSegment } from './segments/shoppingcart/ShoppingCartOrderBuilderSegment';
import { StoriesOrderBuilderSegment } from './segments/stories/StoriesOrderBuilderSegment';
import { TipOrderBuilderSegment } from './segments/tip/TipOrderBuilderSegment';
import { TrackerConsentOrderBuilderSegment } from './segments/trackerconsent/TrackerConsentOrderBuilderSegment';

const useStyles = makeStyles({
	gridContainer: {
		flexShrink: 1,
		overflowY: 'auto',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		WebkitOverflowScrolling: 'touch',
	},
	// Remove white background, retain width and thumb color
	gridContainerMacOs: {
		'&::-webkit-scrollbar': {
			borderRadius: 0,
			width: '15px !important',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: 0,
			width: '15px !important',

			'&:hover': {
				background: '#B9B9B9',
			},
		},

		'&::-webkit-scrollbar-track': {
			backgroundColor: 'transparent !important',
			width: '15px !important',
		},
	},
	PaymentMethodExpansionPanel: {
		flex: '0 1 auto',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'hidden',
	},
	ShrinksVerticallyWithParent: {
		flex: '0 1 auto',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'hidden',
	},
});

export interface OrderBuilderProps
{
	topLeftEdgeRadius?: number;
	topRightEdgeRadius?: number;
	bottomLeftEdgeRadius?: number;
	bottomRightEdgeRadius?: number;
	flexGrow?: boolean;
	store: OrderBuilderStore;
}

export const OrderBuilder: FC<OrderBuilderProps> =
	({
		topLeftEdgeRadius,
		bottomLeftEdgeRadius,
		flexGrow,
		store,
	}) =>
	{
		const {entranceStore} = useContext(EntranceContextRef);
		const {businessStore} = useContext(BusinessContextRef);
		const currentOrderService = useCurrentOrderService();
		const currentPlaceService = useCurrentPlaceService();
		const client = useWebClient(true);

		const classes = useStyles();

		const clearOrderOptionsAfterOrder = useObserver(() => entranceStore.clearOrderOptionsAfterOrder.value);
		const operatingSystem = useObserver(() => client.operatingSystem);
		const showValidation = useObserver(() => store.showValidation);
		const business = useObserver(() => currentPlaceService.business!);
		const place = useObserver(() => currentPlaceService.place!);
		const timeScheduleById = useObserver(() => businessStore.timeScheduleById);
		const setPaymentMethod = useObserver(() => currentOrderService.setPaymentMethod);
		const setPaymentSessionHasError = useObserver(() => store.setPaymentSessionHasError);

		const baseAmount = useObserver(() => currentOrderService.totalProductAmount);
		const totalDiscountAmount = useObserver(() => currentOrderService.totalDiscountAmount);
		const productFeesAmount = useObserver(() => currentOrderService.totalProductFeeAmount);
		const deliveryFeeAmount = useObserver(() => currentOrderService.deliveryFeeAmount);
		const tip = useObserver(() => currentOrderService.tip);
		const additiveTaxAmounts = useObserver(() => currentOrderService.additiveTaxAmounts);
		const baseLineRoutingIds = useObserver(() => currentOrderService.baseLineRoutingIds);

		useEffect(() =>
		{
			store.entersUI(true);

			return () => {
				store.exitsUI(true);
			};
		}, [store]);

		const isKioskMode = useIsKioskMode();

		return <ValidationContextProvider showValidation={showValidation}>
			<ProcessingOrderContextProvider>
				<PlacePaymentContextProvider
					business={business}
					place={place}
					baseAmount={baseAmount}
					discountAmount={totalDiscountAmount}
					productFeesAmount={productFeesAmount}
					deliveryFeeAmount={deliveryFeeAmount}
					tipAmount={tip}
					rawAdditiveTaxAmountPerTaxGroupId={additiveTaxAmounts}
					baseLineRoutingIds={baseLineRoutingIds}
					timing="beforehand"
					resetAfterPayment={clearOrderOptionsAfterOrder}
					onPaymentSessionHasErrorChange={setPaymentSessionHasError}
					timeScheduleById={timeScheduleById}
				>
					<CloudyScrollContainer>
						<Box
							sx={{
								paddingX: '3px',
							}}
						>
							<Grid
								className={clsx(
									classes.gridContainer,
									operatingSystem === 'Mac OS'
										? classes.gridContainerMacOs
										: undefined,
								)}
								container
								spacing={2}
								style={{
									flexGrow: flexGrow ? 1 : 0,
								}}
							>
								<StoriesOrderBuilderSegment store={store} />
								<ShoppingCartOrderBuilderSegment store={store} topLeftEdgeRadius={topLeftEdgeRadius} />
								<PeerShoppingCarts />
								<ProductRecommendationOrderBuilderSegment store={store} />
								<ServiceOrderBuilderSegment store={store} />
								<ComoRewardOrderBuilderSegment store={store}/>
								<CouponOrderBuilderSegment store={store} />
								<SchedulerOrderBuilderSegment store={store} />
								<CommentOrderBuilderSegment store={store} />
								<PasswordOrderBuilderSegment store={store} />
								<CustomerCardOrderBuilderSegment store={store} />
								<PaymentOrderBuilderSegment onChange={setPaymentMethod} store={store} />
								<TipOrderBuilderSegment />
								<TrackerConsentOrderBuilderSegment store={store} />
								<AnnouncementOrderBuilderSegments store={store} />
								<AgeVerificationOrderBuilderSegment store={store} />
								<LegalConsentOrderBuilderSegment store={store} />
							</Grid>
						</Box>
					</CloudyScrollContainer>
					<Grid
						container
						spacing={2}
						style={{
							flexGrow: flexGrow ? 1 : 0,
							flexShrink: 0,
							overflowY: 'hidden',
							flexDirection: 'column',
							flexWrap: 'nowrap',
							justifyContent: 'flex-start',
							paddingTop:
								isKioskMode
									? 24
									: 0,
						}}
					>
						<OrderButtonOrderBuilderSegment
							bottomLeftEdgeRadius={bottomLeftEdgeRadius}
							store={store}
						/>
					</Grid>
				</PlacePaymentContextProvider>
			</ProcessingOrderContextProvider>
		</ValidationContextProvider>;
	};