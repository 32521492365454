import { FC, useMemo } from 'react';
import { useTranslation } from '../../../../../../Bridge/Localization/useTranslation';
import { GenericHistoricalItem } from '../GenericHistoricalItem';

interface HistoricalDiscountItemProps
{
	currency: string;
	discount: number;
}

export const HistoricalDiscountItem: FC<HistoricalDiscountItemProps> =
	({
		currency,
		discount,
	}) =>
	{
		const description = useTranslation('Generic-Discount');

		const price = useMemo(
			() =>
				({
					amount: -discount,
					currency,
				}),
			[currency, discount],
		);

		return <GenericHistoricalItem
			description={description}
			price={price}
		/>;
	};
