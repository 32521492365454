import { CSSProperties } from 'react';
import { ImageElement } from '../../../../../../../Api/v3/model/story_post/content/element/ImageElement';

export function useImageElementStyleProps(
	element: Pick<ImageElement, 'size'>,
	postWidth: number,
): CSSProperties
{
	return {
		width: element.size * postWidth,
	};
}
