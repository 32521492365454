import { Avatar, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useObserver } from 'mobx-react-lite';
import { FC, useContext } from 'react';
import { HateoasStory } from '../../../../../Api/v3/model/story/HateoasStory';
import { useBrandingService } from '../../../../branding-service/BrandingServiceProvider';
import { LocalizedLanguageEntry } from '../../../../localized_language_entry/language_entry/LocalizedLanguageEntry';
import { BusinessContextRef } from '../../../../Page/Business/BusinessContext';

const AvatarSize = 35;

const useStyles = makeStyles(theme => ({
	businessNameText: {
		color: 'rgba(255, 255, 255, 0.63)',
		textShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
	},
	closeButton: {
		color: 'white',
	},
	contentRoot: {
		flexGrow: 1,
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		gap: theme.spacing(1),
	},
	storyNameText: {
		color: theme.palette.common.white,
		fontWeight: 500,
		lineHeight: 1,
		textShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
	},
}));

interface StoryPlayerTitleProps
{
	story: HateoasStory;
	onClose: () => void;
}

export const StoryPlayerTitle: FC<StoryPlayerTitleProps> =
	({
		story,
		onClose,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const brandingService = useBrandingService();

		const business = useObserver(() => businessStore.business);
		const avatarLogoUrl = useObserver(() => brandingService.logoInlineImageUrl ?? brandingService.logoImageUrl);

		const classes = useStyles();

		return <div
			className={classes.root}
		>
			{
				avatarLogoUrl !== undefined &&
				<Avatar
					src={avatarLogoUrl}
					style={{
						width: AvatarSize,
						height: AvatarSize,
						backgroundColor: brandingService.tintColor?.css,
						pointerEvents: 'none',
					}}
				/>
			}
			<div
				className={classes.contentRoot}
			>
				<Typography
					className={classes.storyNameText}
				>
					<LocalizedLanguageEntry
						entry={story.name}
					/>
				</Typography>
				<Typography
					className={classes.businessNameText}
					variant="caption"
				>
					{business.name}
				</Typography>
			</div>
			<IconButton
				className={classes.closeButton}
				onClick={onClose}
			>
				<CloseIcon />
			</IconButton>
		</div>;
	};
