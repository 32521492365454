import { createContext } from 'react';
import { StoryWithPosts } from '../../Page/Business/StoriesPlayer/Model/StoryWithPosts';

interface StoryLoadingContextValue
{
	loadPost: (story: StoryWithPosts, postIdx: number) => Promise<void>;
	loadPostAndAdjacentPosts: (story: StoryWithPosts, postIdx: number) => Promise<void>;
	didLoadPost: (story: StoryWithPosts, postIdx: number) => boolean;
}

export const StoryLoadingContext = createContext<StoryLoadingContextValue>(undefined!);
