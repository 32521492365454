import { ButtonBase, Typography, WithStyles } from '@material-ui/core';
import * as React from 'react';
import { FC, ReactNode } from 'react';
import { Color } from '../../../../Api/Other/Color';
import { ExtendedMenuEntryType } from '../../../../Api/Product/ExtendedMenuEntryType';
import { menuEntryStyles } from '../MenuEntry';
import { ListItemPrimaryBadges } from './badges/ListItemPrimaryBadges';
import { ListItemSecondaryBadges } from './badges/ListItemSecondaryBadges';

export interface ListItemWithoutImageProps
{
	primaryBadges?: ReactNode[];
	secondaryBadges?: ReactNode[];
	minHeight?: number | string;
	onClick?: () => void;
	price?: string;
	title: string;
	type?: ExtendedMenuEntryType;
	textColor?: Color;
	backgroundColor: Color;
	description?: string;
	descriptionInHtml?: string;
	disabled?: boolean;
}

export const ListItemWithoutImage: FC<ListItemWithoutImageProps & WithStyles<typeof menuEntryStyles>> =
	({
		minHeight,
		onClick,
		primaryBadges,
		secondaryBadges,
		title,
		price,
		classes,
		backgroundColor,
		textColor,
		description,
		descriptionInHtml,
		disabled,
}) =>
	{
		return <ButtonBase
			focusRipple
			className={classes.root}
			style={{
				minHeight,
				backgroundColor: backgroundColor.css,
			}}
			onClick={onClick}
			disabled={disabled}
		>
			<span className={classes.textOnlyListItem}>
				<div
					style={{
						overflow: 'hidden',
						display: 'flex',
						flexDirection: 'column',
						marginRight: 7,
						flex: '1 1 auto',
					}}
				>
					<div>
						<Typography
							variant="body1"
							className={classes.title}
							style={{
								color: textColor.css,
								fontWeight: 700,
							}}
						>
							{title}
						</Typography>
					</div>
					{
						description !== undefined && description.length > 0 &&
						(
							descriptionInHtml !== undefined
								?
								<Typography
									variant="body1"
									className={classes.description}
									style={{
										color: textColor.withAlpha(0.6).css,
										fontWeight: 300,
									}}
									dangerouslySetInnerHTML={{__html: descriptionInHtml}}
								/>
								:
								<Typography
									variant="body1"
									className={classes.description}
									style={{
										color: textColor.withAlpha(0.6).css,
										fontWeight: 300,
									}}
								>
									{description}
								</Typography>
						)
					}
					<ListItemSecondaryBadges
						secondaryBadges={secondaryBadges}
					/>
					<Typography
						variant="body1"
						className={classes.title}
						style={{
							display: 'inline-block',
							color: textColor.css,
							fontWeight: 300,
						}}
					>
					</Typography>
				</div>
				<Typography
					variant="body1"
					className={classes.price}
					style={{
						color: textColor.css,
					}}
				>
					{price}
				</Typography>
				<ListItemPrimaryBadges
					primaryBadges={primaryBadges}
				/>
			</span>
		</ButtonBase>;
	};
