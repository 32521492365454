import { FC, memo } from 'react';
import { ColorFill } from '../../../../../Api/v3/model/story_post/content/shared/color_fill/ColorFill';
import { LinearGradientBackgroundViewer } from './linear_gradient/LinearGradientBackgroundViewer';
import { SolidColorBackgroundViewer } from './solid/SolidColorBackgroundViewer';

interface BackgroundViewerProps
{
	backgroundColor: ColorFill;
}

export const BackgroundViewer: FC<BackgroundViewerProps> =
	memo(
		({
			backgroundColor,
		}) =>
		{
			switch (backgroundColor.type)
			{
				case 'LinearGradient':
					return <LinearGradientBackgroundViewer
						backgroundColor={backgroundColor}
					/>;

				case 'Solid':
					return <SolidColorBackgroundViewer
						backgroundColor={backgroundColor}
					/>;

				default:
					return null;
			}
		},
	);
