import { useObserver } from 'mobx-react-lite';
import { FC } from 'react';
import { BusinessScreenContextProvider } from '../../../business_screen_context/BusinessScreenContextProvider';
import { StoriesPlayerContextProvider } from '../../../story/player/context/StoriesPlayerContextProvider';
import { StoriesPlayer } from '../../../story/player/StoriesPlayer';
import { StoriesPlayerStore } from './StoriesPlayerStore';

interface StoriesPlayerDialogProps
{
	store: StoriesPlayerStore;
}

export const StoriesPlayerDialog: FC<StoriesPlayerDialogProps> =
	({
		store,
	}) =>
	{
		const menuStore = useObserver(() => store.businessStore.menuStore);

		return <BusinessScreenContextProvider>
			<StoriesPlayerContextProvider
				stories={store.stories}
				onClose={store.onClose}
			>
				<StoriesPlayer
					menuStore={menuStore}
				/>
			</StoriesPlayerContextProvider>
		</BusinessScreenContextProvider>;
	};
