import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { useTranslation } from '../../../../../Bridge/Localization/useTranslation';
import { BusinessScreenContextProvider } from '../../../../business_screen_context/BusinessScreenContextProvider';
import { PageDialog } from '../../../../UI/PageDialog';
import { History } from '../History';
import { HistoryStore } from '../HistoryStore';

interface HistoryDialogProps
{
	store: HistoryStore;
}

export const HistoryDialog: FC<HistoryDialogProps> =
	({
		store,
	}) =>
	{
		const place = useObserver(() => store.place);

		const title = useTranslation('Client-OrderHistory-Title');

		const handleClose = useCallback(
			() =>
				store.pop(),
			[store],
		);

		if (place === undefined)
		{
			return <PageDialog
				title={title}
				onClose={handleClose}
			>
				<History
					store={store}
				/>
			</PageDialog>;
		}
		else
		{
			return <BusinessScreenContextProvider>
				<PageDialog
					title={title}
					onClose={handleClose}
				>
					<History
						store={store}
					/>
				</PageDialog>
			</BusinessScreenContextProvider>;
		}
	};
