import { trimLeadingSlashes } from '../../trimLeadingSlashes';
import { trimTrailingSlashes } from '../../trimTrailingSlashes';

/**
 * Gets the complete URL of an API resource
 *
 * @param resource the relative URL of the resource
 * @return compelete URL for the resource
 */
export function getV3ApiUrl(resource: string): string
{
	if (resource.startsWith('http://')
		|| resource.startsWith('https://'))
	{
		return resource;
	}
	else
	{
		return `${trimTrailingSlashes(process.env.REACT_APP_API_V3_BASE_URL)}/${trimLeadingSlashes(resource)}`;
	}
}
