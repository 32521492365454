import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, ReactNode, useMemo } from 'react';
import { useLocalizer } from '../../../../../Bridge/Localization/useLocalizer';
import { useCurrentMinute } from '../../../../../Util/clock/useCurrentMinute';
import { useCurrentPlaceService } from '../../../../current-place-service/CurrentPlaceService';
import { shouldShowOnMenu } from '../Api/shouldShowOnMenu';
import { MenuStore } from '../MenuStore';
import { useShowOrderWaiterButton } from '../TopContent/OrderWaiterButton/useShowOrderWaiterButton';
import { useShowRequestBillButton } from '../TopContent/RequestBillButton/useShowRequestBillButton';
import { OrderWaiterButtonListItem } from './OrderWaiterButtonListItem';
import { ProductListItem } from './ProductListItem';
import { RequestBillButtonListItem } from './RequestBillButtonListItem';

const useStyle = makeStyles(theme => ({
	listItem: {
		alignItems: 'stretch !important',
		flexDirection: 'column',
		padding: `${theme.spacing(0.5)}px !important`,
	},
}));

interface SearchResultListProps
{
	className?: string;
	onStartPayingBill: () => void;
	searchQuery?: string;
	store: MenuStore;
}

export const SearchResultList: FC<SearchResultListProps> =
	(
		{
			className,
			onStartPayingBill,
			searchQuery,
			store,
		},
	) =>
	{
		const localizer = useLocalizer();
		const now = useCurrentMinute();
		const {place} = useCurrentPlaceService();

		const showRequestBillButton = useShowRequestBillButton();
		const showOrderWaiterButton = useShowOrderWaiterButton();
		const timeSchedulesIgnored = useObserver(() => place?.timeSchedulesIgnored);

		const availableProducts = useObserver(() =>
		{
			const allProducts = Array.from(store.productById?.values() ?? []);

			if (timeSchedulesIgnored)
			{
				return allProducts;
			}
			else
			{
				return allProducts
					.filter(product => shouldShowOnMenu(product, now));
			}
		});

		const openProduct = useObserver(() => store.openProduct);
		const orderBillLegacy = useObserver(() => store.orderBill);
		const orderWaiter = useObserver(() => store.orderWaiter);
		const useOrderBillLegacy = useObserver(() => store.place.payExternalBillLegacyMode);

		const classes = useStyle();

		const lowercaseSearchQuery = useMemo(() => searchQuery?.toLowerCase(), [searchQuery]);

		const buttonList = useMemo(() => {
			const buttons: ReactNode[] = [];

			if (lowercaseSearchQuery === undefined)
				return undefined;

			if (showOrderWaiterButton)
			{
				const orderWaiterLabel = localizer.translate('Client-Order-CallWaiter');

				buttons.push(
					<OrderWaiterButtonListItem
						className={classes.listItem}
						key="waiter"
						onClick={orderWaiter}
						show={orderWaiterLabel.toLowerCase().includes(lowercaseSearchQuery)}
					/>,
				);
			}

			if (showRequestBillButton)
			{
				const orderBillLabel = localizer.translate(useOrderBillLegacy ? 'Client-Order-RequestBill' : 'Client-Order-PayBill');

				buttons.push(
					<RequestBillButtonListItem
						className={classes.listItem}
						key="bill"
						onClick={onStartPayingBill}
						onClickLegacy={orderBillLegacy}
						show={orderBillLabel.toLowerCase().includes(lowercaseSearchQuery)}
						useLegacy={useOrderBillLegacy}
					/>,
				);
			}

			return buttons;
		}, [classes.listItem, localizer, lowercaseSearchQuery, onStartPayingBill, orderBillLegacy, orderWaiter, showOrderWaiterButton, showRequestBillButton, useOrderBillLegacy]);

		const productList = useMemo(() => {
			if (lowercaseSearchQuery === undefined)
				return undefined;

			return availableProducts.map(product => {

				const matchesName = product.name.toLowerCase().includes(lowercaseSearchQuery);
				const matchesDescription = product.description.toLowerCase().includes(lowercaseSearchQuery);

				return <ProductListItem
					className={classes.listItem}
					key={product.uuid}
					onClick={openProduct}
					product={product}
					show={matchesName || matchesDescription}
				/>;
			});
		}, [availableProducts, classes.listItem, lowercaseSearchQuery, openProduct]);

		return <List className={className}>
			{buttonList}
			{productList}
		</List>;
	};
