import { FC, memo } from 'react';
import { Element } from '../../../../../Api/v3/model/story_post/content/element/Element';
import { ActionContext } from '../action/ActionContext';
import { ImageElementViewer } from './image/ImageElementViewer';
import { TextElementViewer } from './text/TextElementViewer';
import { TextButtonElementViewer } from './text_button/TextButtonElementViewer';

interface ElementContentViewerProps
{
	element: Element;
	actionContext: ActionContext;
}

export const ElementContentViewer: FC<ElementContentViewerProps> = memo(
	({
		element,
		actionContext,
	}) =>
	{
		const type = element.type;

		switch (type)
		{
			case 'Image':
				return <ImageElementViewer
					element={element}
				/>;
			case 'Text':
				return <TextElementViewer
					element={element}
				/>;
			case 'TextButton':
				return <TextButtonElementViewer
					element={element}
					actionContext={actionContext}
				/>;
			default:
				console.warn(`Tried to render unknown type ${type}`);
				return null;
		}
	},
);
