import { Box, Dialog, Drawer, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useIsSmallOrSmallerScreen } from '../../../hooks/screen/useIsSmallOrSmallerScreen';
import { useBrandingService } from '../../branding-service/BrandingServiceProvider';
import { CubeTransition } from '../../cube_transition/CubeTransition';
import { BusinessContextRef } from '../../Page/Business/BusinessContext';
import { MenuStore } from '../../Page/Business/Menu/MenuStore';
import { StoriesPlayerContainer } from './container/StoriesPlayerContainer';
import { StoriesPlayerContext } from './context/StoriesPlayerContext';
import { StoryPlayerContextProvider } from './context/StoryPlayerContextProvider';
import { ActionContext } from './post_viewer/action/ActionContext';
import { StoriesPlayerTransition } from './StoriesPlayerTransition';
import { StoryPlayer } from './StoryPlayer';

const useStyles = makeStyles(theme => ({
	dialogPaper: {
		backgroundColor: 'transparent',
		boxShadow: 'none',
		borderRadius: 2 * theme.shape.borderRadius,
		overflow: 'visible',
	},
	dialogPaperFullscreen: {
		borderRadius: 0,
	},
	drawerContent: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(2),
	},
}));

interface StoriesPlayerProps
{
	menuStore: MenuStore;
}

export const StoriesPlayer: FC<StoriesPlayerProps> =
	({
		menuStore,
	}) =>
	{
		const brandingService = useBrandingService();
		const isFullScreen = useIsSmallOrSmallerScreen();

		const {businessStore} = useContext(BusinessContextRef);
		const {stories, onChangeIsPaused, openStoryIdx, onChangeOpenStoryIdx} = useContext(StoriesPlayerContext);

		const [drawerUi, setDrawerUi] = useState<ReactNode | undefined>(undefined);
		const [drawerTitle, setDrawerTitle] = useState<string | undefined>(undefined);

		const handleClose = useCallback(
			() =>
				onChangeOpenStoryIdx(undefined),
			[onChangeOpenStoryIdx],
		);

		const actionContext = useMemo<ActionContext>(
			() => ({
				businessStore,
				menuStore,
				openDrawer:
					(title, ui) =>
					{
						setDrawerTitle(title);
						setDrawerUi(ui);
						onChangeIsPaused(true);
					},
				closeDrawer:
					() =>
					{
						setDrawerUi(undefined);
						onChangeIsPaused(false);
					},
				close:
					() =>
						onChangeOpenStoryIdx(undefined),
				pause:
					() =>
						onChangeIsPaused(true),
				resume:
					() =>
						onChangeIsPaused(false),
			}),
			[businessStore, menuStore, onChangeIsPaused, onChangeOpenStoryIdx],
		);

		const classes = useStyles();

		return <Dialog
			classes={{
				paper: classes.dialogPaper,
				paperFullScreen: classes.dialogPaperFullscreen,
			}}
			open={openStoryIdx !== undefined}
			onClose={handleClose}
			style={{
				backdropFilter: 'blur(15px)',
			}}
			fullScreen={isFullScreen}
			TransitionComponent={StoriesPlayerTransition}
		>
			{
				openStoryIdx !== undefined &&
				<>
					<StoriesPlayerContainer
						fullScreen={isFullScreen}
						width={400}
					>
						<CubeTransition
							idx={openStoryIdx}
							onChangeIdx={onChangeOpenStoryIdx}
							renderElement={
								({
									idx,
									onPrevious,
									onNext,
								}) =>
								{
									const openStory = stories[idx];

									const goToPreviousStory = () =>
									{
										if (idx === 0)
										{
											handleClose();
										}
										else
										{
											onPrevious();
										}
									};

									const goToNextStory = () =>
									{
										if (idx === stories.length - 1)
										{
											handleClose();
										}
										else
										{
											onNext();
										}
									};

									return <StoryPlayerContextProvider
										story={openStory}
										storyIdx={idx}
										active={idx === openStoryIdx}
										onClose={handleClose}
									>
										<StoryPlayer
											key={openStory.story.id}
											story={openStory}
											actionContext={actionContext}
											active={idx === openStoryIdx}
											fullscreen={isFullScreen}
											onPrevious={
												idx > 0
													? goToPreviousStory
													: undefined
											}
											onNext={goToNextStory}
											onClose={handleClose}
										/>
									</StoryPlayerContextProvider>;
								}
							}
							numberOfElements={stories.length}
							onChangeIsInteractedWith={onChangeIsPaused}
							disabled={drawerUi !== undefined}
						/>
					</StoriesPlayerContainer>
					<Drawer
						open={drawerUi !== undefined}
						onClose={actionContext.closeDrawer}
						variant="temporary"
						anchor="bottom"
						style={{
							backgroundColor: 'transparent',
						}}
						PaperProps={{
							style: {
								backgroundColor: brandingService.backgroundColor.withAlpha(0.7).css,
								backdropFilter: 'blur(15px)',
								display: 'initial',
								maxHeight: '70vh',
								overflowY: 'auto',
							},
						}}
					>
						<div
							className={classes.drawerContent}
						>
							<Box
								sx={{
									flex: 1,
								}}
							>
								<Typography
									style={{
										color: brandingService.backgroundContrastTextColor.css,
									}}
									variant="h4"
								>
									{drawerTitle}
								</Typography>
							</Box>
							<IconButton
								onClick={actionContext.closeDrawer}
							>
								<Close />
							</IconButton>
						</div>
						{drawerUi}
					</Drawer>
				</>
			}
		</Dialog>;
	};
