import { useObserver } from 'mobx-react-lite';
import { FC, PropsWithChildren, useMemo } from 'react';
import { ApiHeaders } from '../../../Api/v3/ApiHeaders';
import { FetchHeadersProvider } from '../../../Api/v3/fetch/FetchHeadersProvider';
import { BusinessStore } from './BusinessStore';

interface BusinessFetchProviderProps
{
	store: BusinessStore;
}

export const BusinessFetchProvider: FC<PropsWithChildren<BusinessFetchProviderProps>> =
	({
		store,
		children,
	}) =>
	{
		const visitId = useObserver(() => store.visitId);
		const businessId = useObserver(() => store.business.uuid);

		const fetchHeaders = useMemo(
			() =>
				visitId === undefined
					? {}
					: ({
						[ApiHeaders.businessId]: businessId,
						[ApiHeaders.visitId]: visitId.toString(),
					}),
			[businessId, visitId],
		);

		return <FetchHeadersProvider
			headers={fetchHeaders}
		>
			{children}
		</FetchHeadersProvider>;
	};
