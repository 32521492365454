import { Fetch } from '../fetch/Fetch';
import { fetchJson } from '../fetch/fetchJson';
import { HateoasCollection, HateoasCollectionElement } from '../model/hateoas/HateoasCollection';

export async function iterateOverHateoasCollection<D extends HateoasCollectionElement, K extends string>(
	fetch: Fetch,
	producer: () => Promise<HateoasCollection<D, K>>,
	collectionName: K,
	consumer: (collection: D[]) => Promise<void>
)
{
	let nextLinkToFetch: string | undefined;

	while (true)
	{
		const collection =
			nextLinkToFetch
				? await fetchJson<HateoasCollection<D, K>>(
					fetch,
					nextLinkToFetch
				)
				: await producer();
		const elements: D[] =
			collection._embedded
				? collection._embedded[collectionName] ?? []
				: [];

		if (elements.length > 0)
		{
			await consumer(elements);
		}

		if (collection._links?.next?.href)
		{
			nextLinkToFetch = collection._links.next.href;
		}
		else
		{
			break;
		}
	}
}
