import { useObserver } from 'mobx-react-lite';
import { FC, useContext, useMemo } from 'react';
import { ImageElement } from '../../../../../../Api/v3/model/story_post/content/element/ImageElement';
import { BusinessContextRef } from '../../../../../Page/Business/BusinessContext';
import { StoryPostContext } from '../../../../context/StoryPostContext';
import { StoryPostWidthProvider } from '../../context/StoryPostWidthProvider';
import { useImageElementStyleProps } from './api/useImageElementStyleProps';

interface ImageElementViewerProps
{
	element: ImageElement;
}

export const ImageElementViewer: FC<ImageElementViewerProps> =
	({
		element,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const postWidth = useContext(StoryPostWidthProvider);
		const {didLoad} = useContext(StoryPostContext);

		const getLoadedStoryPostFile = useObserver(() => businessStore.storyPostFileService.getLoadedStoryPostFile);

		const storyPostFile = useMemo(
			() =>
				didLoad
					? getLoadedStoryPostFile(element.fileId)
					: undefined,
			[didLoad, element.fileId, getLoadedStoryPostFile],
		);

		const imageStyle = useImageElementStyleProps(element, postWidth);

		return <img
			alt={storyPostFile?.fileName ?? 'image'}
			src={storyPostFile?.fileUrl}
			style={imageStyle}
			draggable={false}
		/>;
	};
