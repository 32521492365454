import { makeAutoObservable, observable } from 'mobx';
import { Fetch } from '../../Api/v3/fetch/Fetch';
import { fetchJson } from '../../Api/v3/fetch/fetchJson';
import { HateoasStoryPost } from '../../Api/v3/model/story_post/HateoasStoryPost';
import { HateoasStoryPostFile } from '../../Api/v3/model/story_post_file/HateoasStoryPostFile';
import { getV3ApiUrl } from '../../Util/Api/Resources/getV3ApiUrl';

export class StoryPostFileService
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	private readonly storyPostFileById = observable.map<string, HateoasStoryPostFile>();

	/*---------------------------------------------------------------*
	 *                          Constructor                          *
	 *---------------------------------------------------------------*/

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	/*---------------------------------------------------------------*
	 *                            Actions                            *
	 *---------------------------------------------------------------*/

	public getLoadedStoryPostFile(id: string): HateoasStoryPostFile | undefined
	{
		return this.storyPostFileById.get(id);
	}

	public async loadStoryPostFile(fetch: Fetch, id: string, isImage?: boolean): Promise<HateoasStoryPostFile>
	{
		return new Promise(
			async (resolve, reject) =>
			{
				try
				{
					const existingValue = this.storyPostFileById.get(id);

					if (existingValue === undefined)
					{
						const result = await fetchJson<HateoasStoryPostFile>(
							fetch,
							getV3ApiUrl(`/storyPostFiles/${id}`),
						);

						this.storyPostFileById.set(result.id, result);

						if (isImage)
						{
							// Preload image
							const image = new Image();
							image.addEventListener('load', () => resolve(result), {once: true});
							image.addEventListener('error', event => reject(event.error), {once: true});
							image.src = result.fileUrl;
						}
						else
						{
							return resolve(result);
						}
					}
					else
					{
						return resolve(existingValue);
					}
				}
				catch (error)
				{
					reject(error);
				}
			},
		);
	}

	public async loadStoryPostFiles(fetch: Fetch, post: HateoasStoryPost): Promise<void>
	{
		if (post.content.overlay?.type === 'Image')
			await this.loadStoryPostFile(fetch, post.content.overlay.fileId, true);

		if (post.content.overlay?.type === 'Video')
		{
			await this.loadStoryPostFile(fetch, post.content.overlay.fileId, false);

			if (post.content.overlay.posterFileId !== undefined)
				await this.loadStoryPostFile(fetch, post.content.overlay.posterFileId, true);
		}

		await Promise.all(
			post
				.content
				.elements
				.filter(element => element.type === 'Image')
				.map(element => this.loadStoryPostFile(fetch, element.fileId, true)),
		);
	}
}
