import { FC } from 'react';
import { BusinessScreenContextProvider } from '../../business_screen_context/BusinessScreenContextProvider';
import { MenuStore } from '../Business/Menu/MenuStore';
import { MenuWrapper } from '../Business/Menu/MenuWrapper';


interface MenuPageProps
{
	onStartPayingBill: () => void;
	openNavigationMenu?: () => void;
	store: MenuStore;
}

export const MenuPage: FC<MenuPageProps> =
	({
		onStartPayingBill,
		openNavigationMenu,
		store,
	}) =>
	{
		return <BusinessScreenContextProvider>
			<MenuWrapper
				onStartPayingBill={onStartPayingBill}
				openNavigationMenu={openNavigationMenu}
				store={store}
			/>
		</BusinessScreenContextProvider>;
	};
