import { useObserver } from 'mobx-react-lite';
import { FC, useContext } from 'react';
import { StoryButtons } from '../../../../../story/buttons/StoryButtons';
import { BusinessContextRef } from '../../../BusinessContext';
import { OrderBuilderSegment } from '../../OrderBuilderSegment';
import { OrderBuilderStore } from '../../OrderBuilderStore';

interface StoriesOrderBuilderSegmentProps
{
	store: OrderBuilderStore;
}

export const StoriesOrderBuilderSegment: FC<StoriesOrderBuilderSegmentProps> =
	({
		store,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);

		const checkoutStories = useObserver(() => store.checkoutStories);
		const menuStore = useObserver(() => businessStore.menuStore);

		if (checkoutStories.length > 0)
		{
			return <OrderBuilderSegment>
				<StoryButtons
					stories={checkoutStories}
					menuStore={menuStore}
				/>
			</OrderBuilderSegment>;
		}
		else
		{
			return null;
		}
	};
