import { FetchError } from './exception/FetchError';
import { HttpError } from './exception/HttpError';
import { Fetch, RequestInitWithParams } from './Fetch';

export function fetchJson<T>(
	fetch: Fetch,
	input: RequestInfo,
	init?: RequestInitWithParams,
): Promise<T>
{
	return fetch(
		input,
		init,
	)
		.catch(
			error =>
				Promise.reject(
					new FetchError(
						init?.method ?? 'GET',
						typeof input === 'string' ? input : input.url,
						extractErrorMessage(error),
					),
				),
		)
		.then(
			response =>
			{
				// no content
				if (response.status === 204)
				{
					return null;
				}
				else
				{
					return response
						.json()
						.catch(() =>
						{
							return response.text()
								.catch(error =>
									Promise.reject(
										new HttpError(
											init?.method ?? 'GET',
											typeof input === 'string' ? input : input.url,
											response.status,
											{
												error: extractErrorMessage(error),
											},
										),
									),
								)
								.then(error =>
									Promise.reject(
										new HttpError(
											init?.method ?? 'GET',
											typeof input === 'string' ? input : input.url,
											response.status,
											{error},
										),
									),
								);
						})
						.then(json =>
						{
							if (response.ok)
								return json;
							else
								return Promise.reject(
									new HttpError(
										init?.method ?? 'GET',
										typeof input === 'string' ? input : input.url,
										response.status,
										json,
									),
								);
						});
				}
			},
		);
}

function extractErrorMessage(error: unknown): string | undefined
{
	if (error instanceof Error)
		return error.message;
	else if (typeof error === 'string')
		return error;
	else
		return undefined;
}
