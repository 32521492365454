import { BusinessFont } from './BusinessFont';
import { DefaultFont } from './DefaultFont';

export type Font =
	DefaultFont
	| BusinessFont;

export function isBusinessFont(value: Font): value is BusinessFont
{
	return value.type === 'Business';
}
