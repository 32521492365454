import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../../../../RootContext';
import { CallTheWaiterIcon } from './Icon/CallTheWaiterIcon';
import { TopContentButton } from './TopContentButton';

interface OrderWaiterButtonProps
{
	onClick: () => void;
}

export const OrderWaiterButton: FC<OrderWaiterButtonProps> =
	(
		{
			onClick,
		},
	) =>
	{
		const {brandingService} = useRootContext(true);
		const iconColor = useObserver(() => brandingService.tintContrastTextColor.css);
		const translate = useTranslate();

		return <TopContentButton
			icon={
				<CallTheWaiterIcon
					color={iconColor}
				/>
			}
			label={translate('Client-Order-CallWaiter')}
			onClick={onClick}
		/>;
	};
