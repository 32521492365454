import 'array-flat-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Root } from './Component/Root/Root';
import { registerServiceWorker } from './Component/ServiceWorker/registerServiceWorker';
import { initializeSentry } from './lib/sentry/initializeSentry';
import './style.scss';

initializeSentry();

ReactDOM.render(
	<Root />,
	document.getElementById('root')
);

/* Register the old service worker on load to make the PWA installable */
/* TODO: Should probably start using the default way CRA registers a service worker */
window.addEventListener('load', registerServiceWorker);

/* Register service worker on load to make the PWA installable */
// window.addEventListener('load', registerServiceWorker);
// serviceWorker.register();
