import { Badge, Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { postJson } from '../../../../../../Api/v3/fetch/postJson';
import { useFetch } from '../../../../../../Api/v3/fetch/useFetch';
import { TextButtonElement } from '../../../../../../Api/v3/model/story_post/content/element/TextButtonElement';
import { CreateStoryPostClickRequest } from '../../../../../../Api/v3/model/story_post_click/CreateStoryPostClickRequest';
import { HateoasStoryPostClick } from '../../../../../../Api/v3/model/story_post_click/HateoasStoryPostClick';
import { useLocalizedLanguageEntry } from '../../../../../localized_language_entry/language_entry/useLocalizedLanguageEntry';
import { BusinessContextRef } from '../../../../../Page/Business/BusinessContext';
import { StoryPostContext } from '../../../../context/StoryPostContext';
import { ActionContext } from '../../action/ActionContext';
import { performAction } from '../../action/performAction';
import { StoryPostHeightProvider } from '../../context/StoryPostHeightProvider';
import { useTextElementStyleProps } from '../text/api/useTextElementStyleProps';
import { useTextButtonElementClassName } from './api/useTextButtonElementClassName';

const useStyles = makeStyles(() => ({
	badgeAnimate: {
		animation: '$badge 250ms ease',
	},
	'@keyframes badge': {
		'0%': {
			scale: 1,
		},
		'50%': {
			scale: 1.5,
		},
		'100%': {
			scale: 1,
		},
	},
}));

interface TextButtonElementViewerProps
{
	element: TextButtonElement;
	actionContext: ActionContext;
}

export const TextButtonElementViewer: FC<TextButtonElementViewerProps> =
	({
		element,
		actionContext,
	}) =>
	{
		const fetch = useFetch();
		const {currentOrderService} = useContext(BusinessContextRef);
		const postHeight = useContext(StoryPostHeightProvider);
		const {post} = useContext(StoryPostContext);

		const [counter, setCounter] = useState(undefined);
		const [animate, setAnimate] = useState(false);

		const quantityByProductUuid = useObserver(() => currentOrderService.quantityByProductUuid);

		useEffect(
			() =>
			{
				if (element.action.type === 'AddProductToCart' || element.action.type === 'OpenProduct')
				{
					const quantity = quantityByProductUuid.get(element.action.productId);

					if (quantity > 0)
					{
						setCounter(quantity);

						if (quantity !== counter)
							setAnimate(true);
					}
				}
			},
			[counter, element.action, quantityByProductUuid],
		);

		const localizedText = useLocalizedLanguageEntry(element.text);

		const textButtonClassName = useTextButtonElementClassName(element, postHeight);
		const textElementStyle = useTextElementStyleProps(element, postHeight);

		const saveClick = useCallback(
			() =>
				postJson<HateoasStoryPostClick, CreateStoryPostClickRequest>(
					fetch,
					'/storyPostClicks',
					{
						postId: post.id,
						elementId: element.id,
						date: new Date().toISOString(),
					},
				),
			[element.id, fetch, post.id],
		);

		const handleClick = useCallback(
			async () =>
			{
				await performAction(element.action, actionContext);
				await saveClick();
			},
			[actionContext, element.action, saveClick],
		);

		const handleBadgeAnimationEnd = useCallback(
			() =>
				setAnimate(false),
			[],
		);

		const classes = useStyles();

		return <Badge
			badgeContent={counter}
			classes={{
				badge: clsx({
					[classes.badgeAnimate]: counter !== undefined && animate,
				}),
			}}
			color="primary"
			invisible={counter === undefined}
			max={99}
			overlap="rectangular"
			onAnimationEnd={handleBadgeAnimationEnd}
		>
			<Button
				className={textButtonClassName}
				onClick={handleClick}
				variant="contained"
			>
				<Typography
					component="span"
					style={{
						...textElementStyle,
						textTransform: 'none',
					}}
				>
					{localizedText}
				</Typography>
			</Button>
		</Badge>;
	};
