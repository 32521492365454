import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { StoryWithPosts } from '../../../Page/Business/StoriesPlayer/Model/StoryWithPosts';
import { StoryPlayerProgress } from './progress/StoryPlayerProgress';
import { StoryPlayerTitle } from './title/StoryPlayerTitle';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,

		background: 'linear-gradient(rgba(0, 0, 0, 0.5), transparent)',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),
		padding: theme.spacing(1),
		userSelect: 'none',
		WebkitUserSelect: 'none',
	},
}));

interface StoryPlayerHeaderProps
{
	story: StoryWithPosts;
	active: boolean;
	postIdx: number;
	onChangePostIdx: (postIdx: number) => void;
	onClose: () => void;
	onFinish: () => void;
}

export const StoryPlayerHeader: FC<StoryPlayerHeaderProps> =
	({
		story,
		active,
		postIdx,
		onChangePostIdx,
		onClose,
		onFinish,
	}) =>
	{
		const classes = useStyles();

		return <div
			className={classes.root}
		>
			<StoryPlayerProgress
				posts={story.posts}
				postIdx={postIdx}
				active={active}
				onContinue={
					() =>
					{
						if (postIdx === story.posts.length - 1)
						{
							onFinish();
						}
						else
						{
							onChangePostIdx(postIdx + 1);
						}
					}
				}
			/>
			<StoryPlayerTitle
				story={story.story}
				onClose={onClose}
			/>
		</div>;
	};
