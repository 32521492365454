import { createElement } from 'react';
import { OpenProductCategoryAction } from '../../../../../Api/v3/model/story_post/content/action/OpenProductCategoryAction';
import { Menu } from '../../../../Page/Business/Menu/Menu';
import { ActionContext } from './ActionContext';

export async function performOpenProductCategoryAction(
	action: OpenProductCategoryAction,
	context: ActionContext,
)
{
	const productCategory = context.businessStore.productCategoryByUuid.get(action.productCategoryId);

	if (productCategory !== undefined)
	{
		const menuStore = context.businessStore.createMenuStore(
			productCategory,
			context.menuStore,
			false,
		);

		context.openDrawer(
			productCategory.name,
			createElement(
				Menu,
				{
					onStartPayingBill: () => {},
					store: menuStore,
					embedded: true,
				},
			),
		);
	}
}
