import { Fetch } from '../../../../../Api/v3/fetch/Fetch';
import { fetchJson } from '../../../../../Api/v3/fetch/fetchJson';
import { collectHateoasCollection } from '../../../../../Api/v3/hateoas/collectHateoasCollection';
import { HateoasStoryCollection } from '../../../../../Api/v3/model/story/HateoasStoryCollection';
import { HateoasStoryPostCollection } from '../../../../../Api/v3/model/story_post/HateoasStoryPostCollection';
import { groupBy } from '../../../../../Util/map/groupBy';
import { StoryWithPosts } from '../Model/StoryWithPosts';

export async function loadStoriesWithPosts(
	fetch: Fetch,
): Promise<StoryWithPosts[]>
{
	const stories = await collectHateoasCollection(
		fetch,
		() =>
			fetchJson<HateoasStoryCollection>(
				fetch,
				'/stories',
				{
					params: {
						filter: 'isEnabled==true',
						orderBy: 'sortIndex asc',
					},
				},
			),
		'storyResourceList',
	);

	if (stories.length > 0)
	{
		const posts = await collectHateoasCollection(
			fetch,
			() =>
				fetchJson<HateoasStoryPostCollection>(
					fetch,
					'/storyPosts',
					{
						params: {
							filter: `isEnabled==true and storyId=in=(${stories.map(story => story.id).join(',')})`,
							orderBy: 'sortIndex asc',
						},
					},
				),
			'storyPostResourceList',
		);

		const postsByStoryId = groupBy(
			posts,
			post =>
				post.storyId,
		);

		return stories
			.map(
				story =>
					({
						story,
						posts: postsByStoryId.get(story.id) ?? [],
					}),
			)
			.filter(
				({posts}) =>
					posts.length > 0,
			);
	}
	else
	{
		return [];
	}
}
