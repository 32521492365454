import { ProductCategoryStoryLocation } from '../../../../../Api/v3/model/story/location/ProductCategoryStoryLocation';
import { ProductStoryLocation } from '../../../../../Api/v3/model/story/location/ProductStoryLocation';
import { StoryLocation } from '../../../../../Api/v3/model/story/location/StoryLocation';
import { StoryWithPosts } from '../Model/StoryWithPosts';
import { flattenStoryLocation } from './flattenStoryLocation';

export function getStoriesFilteredByLocation(
	location: StoryLocation,
	storiesWithPosts: StoryWithPosts[],
): StoryWithPosts[]
{
	return storiesWithPosts.filter(
		storyWithPosts =>
			flattenStoryLocation(storyWithPosts.story.location).some(
				storyLocation =>
					storyLocationsAreEqual(storyLocation, location),
			),
	);
}

function storyLocationsAreEqual(location1: StoryLocation, location2: StoryLocation): boolean
{
	if (location1.type === location2.type)
	{
		if (location1.type === 'Product')
			return location1.productId === (location2 as ProductStoryLocation).productId;
		else if (location1.type === 'ProductCategory')
			return location1.productCategoryId === (location2 as ProductCategoryStoryLocation).productCategoryId;
		else if (location1.type === 'Composite')
			throw new Error('Equality checks for composite story locations are omitted');
		else
			return true;
	}
	else
	{
		return false;
	}
}
