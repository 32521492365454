import { useObserver } from 'mobx-react-lite';
import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useFetch } from '../../../Api/v3/fetch/useFetch';
import { BusinessContextRef } from '../../Page/Business/BusinessContext';
import { StoryWithPosts } from '../../Page/Business/StoriesPlayer/Model/StoryWithPosts';
import { StoryLoadingContext } from './StoryLoadingContext';

export const StoryLoadingContextProvider: FC =
	({
		children,
	}) =>
	{
		const fetch = useFetch();
		const {businessStore} = useContext(BusinessContextRef);

		const [loadedPosts, setLoadedPosts] = useState<string[]>([]);
		const loadedPostsRef = useRef(loadedPosts);

		const loadFonts = useObserver(() => businessStore.fontService.loadFonts);
		const loadStoryPostFiles = useObserver(() => businessStore.storyPostFileService.loadStoryPostFiles);

		const fontContainerRef = useRef<HTMLDivElement>(null);

		const loadPost = useCallback(
			async (
				story: StoryWithPosts,
				postIdx: number,
			) =>
			{
				const key = toKey(story, postIdx);

				// Using a reference, since only the loaded posts at the moment of execution are relevant
				if (!loadedPostsRef.current.includes(key))
				{
					const post = story.posts[postIdx];

					await Promise.all([
						loadFonts(fetch, post, fontContainerRef),
						loadStoryPostFiles(fetch, post),
					]);

					setLoadedPosts(
						prev =>
							[
								...prev,
								key,
							]);
				}
			},
			[fetch, loadFonts, loadStoryPostFiles],
		);

		const loadPostAndAdjacentPosts = useCallback(
			async (
				story: StoryWithPosts,
				postIdx: number,
			) =>
			{
				fontContainerRef.current.replaceChildren();

				await loadPost(story, postIdx);

				if (postIdx > 0)
					await loadPost(story, postIdx - 1);

				if (postIdx < story.posts.length - 1)
					await loadPost(story, postIdx + 1);
			},
			[loadPost],
		);

		const didLoadPost = useCallback(
			(
				story: StoryWithPosts,
				postIdx: number,
			) =>
				loadedPosts.includes(
					toKey(story, postIdx),
				),
			[loadedPosts],
		);

		const contextValue = useMemo(() => ({
			loadPost,
			loadPostAndAdjacentPosts,
			didLoadPost,
		}), [didLoadPost, loadPost, loadPostAndAdjacentPosts]);

		return <StoryLoadingContext.Provider
			value={contextValue}
		>
			<div
				aria-hidden="true"
				ref={fontContainerRef}
				style={{
					height: 0,
					width: 0,
					overflow: 'hidden',
				}}
			/>
			{children}
		</StoryLoadingContext.Provider>;
	};

function toKey(story: StoryWithPosts, postIdx: number): string
{
	return `${story.story.id}_${postIdx}`;
}
