import { useObserver } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { isTrueAtInCache } from '../../../../../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import { useRootContext } from '../../../../../../RootContext';
import { useCurrentPlaceService } from '../../../../../current-place-service/CurrentPlaceService';
import { useIsRoutingByIdLive } from '../../../../../routing-state-provider/useIsRoutingByIdLive';
import { BusinessContextRef } from '../../../BusinessContext';

export function useShowOrderWaiterButton()
{
	const {businessStore} = useContext(BusinessContextRef);
	const {place} = useCurrentPlaceService();
	const {clockService} = useRootContext(true);
	const currentMinute = useObserver(() => clockService.currentMinute);

	const timeScheduleById = useObserver(() => businessStore.timeScheduleById);
	const isOrderWaiterSupported = useObserver(() => place.isOrderWaiterSupported);
	const requestWaiterRoutingId = useObserver(() => place?.requestWaiterRoutingId);
	const isRoutingLive = useIsRoutingByIdLive(requestWaiterRoutingId);
	const isRoutingStatusIgnored = useObserver(() => place?.routingStatusIgnored);
	const hasNoRoutingOrRoutingIsLive = requestWaiterRoutingId === undefined || isRoutingLive || isRoutingStatusIgnored;

	const isVisibleInTimeSchedule = useMemo(
		() =>
		{
			if (place?.requestWaiterTimeScheduleId === undefined)
			{
				return true;
			}
			else
			{
				const timeSeries = timeScheduleById.get(place.requestWaiterTimeScheduleId)?.booleanTimeSeries;

				if (timeSeries === undefined)
				{
					return true;
				}
				else
				{
					return isTrueAtInCache(timeSeries, currentMinute);
				}
			}
		},
		[currentMinute, place.requestWaiterTimeScheduleId, timeScheduleById],
	);

	return isOrderWaiterSupported && hasNoRoutingOrRoutingIsLive && isVisibleInTimeSchedule;
}