import { OpenLinkAction } from '../../../../../Api/v3/model/story_post/content/action/OpenLinkAction';
import { ActionContext } from './ActionContext';

export async function performOpenLinkAction(
	action: OpenLinkAction,
	context: ActionContext,
)
{
	window.addEventListener('focus', context.resume, {once: true});

	context.pause();

	window.open(
		action.url,
		'_blank',
	);
}
