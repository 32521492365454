export class FetchError extends Error
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	public readonly name = 'FetchError';

	public readonly method: string;

	public readonly url: string;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		method: string,
		url: string,
		message?: string,
	)
	{
		super(
			message === undefined
				? `Failed to fetch ${method} ${url} `
				: `Received "${message}" while fetching ${method} ${url}`,
		);

		this.method = method;
		this.url = url;
	}
}
