import { FC, memo } from 'react';
import { Element } from '../../../../../Api/v3/model/story_post/content/element/Element';
import { ActionContext } from '../action/ActionContext';
import { ElementContentViewer } from './ElementContentViewer';

interface ElementViewerProps
{
	element: Element;
	actionContext: ActionContext;
}

export const ElementViewer: FC<ElementViewerProps> = memo(
	({
		element,
		actionContext,
	}) =>
	{
		return <div
			style={{
				position: 'absolute',
				left: `${element.position.x * 100}%`,
				top: `${element.position.y * 100}%`,

				transform:
					element.rotation === 0
						? 'translate(-50%, -50%)'
						: `translate(-50%, -50%) rotate(${element.rotation}degrees)`,
				transition: 'all 300ms ease-in-out',
				zIndex: element.type === 'TextButton'
					? 1
					: undefined,
			}}
		>
			<ElementContentViewer
				element={element}
				actionContext={actionContext}
			/>
		</div>;
	},
);
