import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { StoryWithPosts } from '../../../Page/Business/StoriesPlayer/Model/StoryWithPosts';
import { StoriesPlayerContext } from './StoriesPlayerContext';

interface StoriesPlayerContextProviderProps
{
	stories: StoryWithPosts[];
	initialOpenStoryIdx?: number;
	onClose: () => void;
}

export const StoriesPlayerContextProvider: FC<StoriesPlayerContextProviderProps> =
	({
		children,
		stories,
		initialOpenStoryIdx = 0,
		onClose,
	}) =>
	{
		const [openStoryIdx, setOpenStoryIdx] = useState(initialOpenStoryIdx);
		const [isPaused, setIsPaused] = useState(false);

		const fontContainerRef = useRef<HTMLDivElement>(null);

		const onChangeOpenStoryIdx = useCallback(
			(value: number | undefined) =>
			{
				if (value === undefined)
				{
					onClose();
				}
				else
				{
					setOpenStoryIdx(value);
				}
			},
			[onClose],
		);

		const contextValue = useMemo(() => ({
			stories,
			isPaused,
			onChangeIsPaused: setIsPaused,
			openStoryIdx,
			onChangeOpenStoryIdx,
		}), [isPaused, onChangeOpenStoryIdx, openStoryIdx, stories]);

		return <StoriesPlayerContext.Provider
			value={contextValue}
		>
			<div
				aria-hidden="true"
				ref={fontContainerRef}
				style={{
					height: 0,
					width: 0,
					overflow: 'hidden',
				}}
			/>
			{children}
		</StoriesPlayerContext.Provider>;
	};
