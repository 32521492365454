import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import { FC } from 'react';
import { useBrandingService } from '../../../branding-service/BrandingServiceProvider';
import { useLocalizedLanguageEntry } from '../../../localized_language_entry/language_entry/useLocalizedLanguageEntry';
import { StoryWithPosts } from '../../../Page/Business/StoriesPlayer/Model/StoryWithPosts';
import { StoryAvatar } from './avatar/StoryAvatar';

const ContainerWidth = 100;

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(0.5),
		width: ContainerWidth,
	},
}));

interface StoryButtonProps
{
	story: StoryWithPosts;
	onClick: () => void;
}

export const StoryButton: FC<StoryButtonProps> =
	({
		story,
		onClick,
	}) =>
	{
		const brandingService = useBrandingService();

		const localizedName = useLocalizedLanguageEntry(story.story.name);

		const backgroundContrastTextColor = useObserver(() => brandingService.backgroundContrastTextColor);

		const classes = useStyles();

		return <div
			className={classes.root}
		>
			<ButtonBase
				style={{
					borderRadius: '50%',
				}}
				onClick={onClick}
				title={localizedName}
			>
				<StoryAvatar
					story={story}
				/>
			</ButtonBase>
			<Typography
				variant="caption"
				style={{
					color: backgroundContrastTextColor.css,
					display: '-webkit-box',
					overflow: 'hidden',
					textAlign: 'center',
					textOverflow: 'ellipsis',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: 2,
					width: '100%',
				}}
			>
				{localizedName}
			</Typography>
		</div>;
	};
