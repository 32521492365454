export interface ColorWithTransparency
{
	red: number;
	green: number;
	blue: number;
	alpha: number;
}

export function toCss({red, green, blue, alpha}: ColorWithTransparency): string
{
	return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}
