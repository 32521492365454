export const Screens =
{
    Entrance: 'Entrance',
    Scanner: 'Scanner',
    Business: 'Business',
    EntranceNavigationMenu: 'EntranceNavigationMenu',
    BusinessNavigationMenu: 'BusinessNavigationMenu',
    BusinessOrderBuilderSidebar: 'OrderBuilderSidebar',
    Menu: 'Menu',
    ComoRewards: 'ComoRewards',
    ComoReward: 'ComoReward',
    Product: 'Product',
    Announcement: 'Announcement',
    ProductRecommendations: 'ProductRecommendations',
    Profile: 'Profile',
    History: 'History',
    OrderBuilder: 'OrderBuilder',
    Login: 'Login',
    Manager: 'Manager',
    OpenMenu: 'OpenMenu',
    Bill: 'Bill',
    AgeVerificationVoucher: 'AgeVerificationVoucher',
    StoriesPlayer: 'StoriesPlayer',
};
