import { Grow, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { forwardRef, ReactElement } from 'react';
import { useIsSmallOrSmallerScreen } from '../../../hooks/screen/useIsSmallOrSmallerScreen';

interface StoriesPlayerTransitionProps extends TransitionProps
{
	children: ReactElement;
}

export const StoriesPlayerTransition = forwardRef<HTMLElement, StoriesPlayerTransitionProps>(
	(props, ref) =>
	{
		const isSmallOrSmallerScreen = useIsSmallOrSmallerScreen();

		if (isSmallOrSmallerScreen)
		{
			return <Slide
				direction="up"
				ref={ref}
				{...props}
			/>;
		}
		else
		{
			return <Grow
				ref={ref}
				{...props}
			/>;
		}
	},
);
