import { BaseStore } from '@intentic/ts-foundation';
import { BusinessStore } from '../BusinessStore';
import { StoryWithPosts } from './Model/StoryWithPosts';

export class StoriesPlayerStore extends BaseStore
{
	// ------------------------ Dependencies ------------------------

	// ------------------------- Properties -------------------------

	businessStore: BusinessStore;
	stories: StoryWithPosts[];
	onClose: () => void;

	// ------------------------ Constructor -------------------------

	constructor(
		businessStore: BusinessStore,
		stories: StoryWithPosts[],
		onClose: () => void,
	)
	{
		super();

		this.businessStore = businessStore;
		this.stories = stories;
		this.onClose = onClose;
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------
}
