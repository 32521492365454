import { FC, memo } from 'react';
import { Overlay } from '../../../../../Api/v3/model/story_post/content/overlay/Overlay';
import { ImageOverlayViewer } from './image/ImageOverlayViewer';
import { VideoOverlayViewer } from './video/VideoOverlayViewer';

interface OverlayViewerProps
{
	overlay: Overlay;
	paused?: boolean;
}

export const OverlayViewer: FC<OverlayViewerProps> = memo(
	({
		overlay,
		paused,
	}) =>
	{
		switch (overlay.type)
		{
			case 'Image':
				return <ImageOverlayViewer
					overlay={overlay}
				/>;

			case 'Video':
				return <VideoOverlayViewer
					overlay={overlay}
					paused={paused}
				/>;

			default:
				return null;
		}
	},
);
