import { useObserver } from 'mobx-react-lite';
import { FC, useContext, useEffect, useMemo } from 'react';
import { ImageOverlay } from '../../../../../../Api/v3/model/story_post/content/overlay/ImageOverlay';
import { BusinessContextRef } from '../../../../../Page/Business/BusinessContext';
import { StoryPostContext } from '../../../../context/StoryPostContext';
import { StoryPlayerContext } from '../../../context/StoryPlayerContext';

interface ImageOverlayViewerProps
{
	overlay: ImageOverlay;
}

export const ImageOverlayViewer: FC<ImageOverlayViewerProps> =
	({
		overlay,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const {onChangeIsPlaying} = useContext(StoryPlayerContext);
		const {active, didLoad} = useContext(StoryPostContext);

		const getLoadedStoryPostFile = useObserver(() => businessStore.storyPostFileService.getLoadedStoryPostFile);

		const storyPostFile = useMemo(
			() =>
				didLoad
					? getLoadedStoryPostFile(overlay.fileId)
					: undefined,
			[didLoad, getLoadedStoryPostFile, overlay.fileId],
		);

		useEffect(
			() =>
			{
				if (active && didLoad)
					onChangeIsPlaying(true);
			},
			[active, didLoad, onChangeIsPlaying],
		);

		return <div
			style={{
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,

				backgroundImage:
					storyPostFile
						? `url(${storyPostFile.fileUrl})`
						: undefined,
				backgroundPosition: `${overlay.position.x * 100}% ${overlay.position.y * 100}%`,
				backgroundSize: overlay.fit.toLowerCase(),
			}}
		/>;
	};
