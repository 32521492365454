import { CardContent, CardMedia, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { useWebClient } from '../../../../Bridge/Client/WebClientProvider';
import { getFileUrl } from '../../../../Util/Api/Resources/getFileUrl';
import { ProductConfiguration } from './ProductConfiguration/ProductConfiguration';
import { ProductInformation } from './ProductInformation';
import { ProductStore } from './ProductStore';

const height = 300;
const fabHeight = 54;

const useStyles = makeStyles((theme: Theme) => ({
    topCard: {
        position: 'relative',
    },
    topCardMediaTitleInImage: {
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 200px)',
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: 'calc(100vh - 300px)',
        },
        minHeight: '35vh',
    },
    topCardMediaTitleBelowImage: {
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 200px)',
        },
        [theme.breakpoints.up('md')]: {
            maxHeight: 'calc(100vh - 340px)',
        },
        minHeight: '35vh',
    },
    badgeRoot: {
        position: 'absolute',
        top: height - fabHeight / 2,
        bottom: theme.spacing(4),
        right: theme.spacing(2),
    },
    badge: {
        position: 'relative',
        right: 20,
        top: -10,
    },
    fab: {
        color: 'white',
    },
}));

interface ProductProps
{
    store: ProductStore
}

export const Product: FC<ProductProps> =
    (
        {
            store,
        }
    ) =>
    {
        const {imageHeight, imageWidth} = useProductImageDimensions(store);
        const client = useWebClient(true);
        const classes = useStyles();
        return useObserver(() => {
            const hasImage = store.product.imageUrl !== undefined;
            const imageBackgroundColor = store.product.imageBackgroundColor;
            const imageHasBackgroundColor = imageBackgroundColor !== undefined;
            const imageBackgroundIsWhite = imageBackgroundColor !== undefined
                && imageBackgroundColor?.r === 255
                && imageBackgroundColor?.g === 255
                && imageBackgroundColor?.b === 255;
            const displayDivider = imageBackgroundColor !== undefined
                ?
                imageBackgroundIsWhite
                :
                true;
            const imageIsTextContrastColorDark = store.product.imageIsTextContrastColorDark === true;

            // TODO: Future work -> Handle false and undefined differently.
            const displayTitleInImageSpace = store.product.detailTitleOverPicture ?? false;

            const imageFileUrl = getFileUrl(hasImage ? store.product.imageUrl : store.product.category.imageUrl!);

            const imageDoContain = hasImage
                ? store.product.imageDoContain
                : store.product.category.imageDoContain

            return <div
                style={{
                    overflow: 'hidden',
                }}
            >
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        {
                            !store.configurationStore && hasImage &&
                            <div
                                style={{
                                    position: 'relative',
                                }}
                            >
                                {
                                    displayTitleInImageSpace &&
                                    <Typography
                                        variant="h4"
                                        style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            color: imageIsTextContrastColorDark ? 'black' : 'white',
                                            margin: '11px 20px',
                                            textShadow: imageHasBackgroundColor
                                                ?
                                                undefined
                                                :
                                                'rgba(0, 0, 0, 0.5) 0px 0px 15px, ' +
                                                'rgba(0, 0, 0, 0.5) 0px 0px 2px, ' +
                                                'rgba(0, 0, 0, 0.4) 0px 0px 6px',
                                        }}
                                    >
                                        {store.product.name}
                                    </Typography>
                                }
                                <CardMedia
                                    image={imageFileUrl}
                                    classes={{
                                        root: displayTitleInImageSpace
                                            ?
                                            classes.topCardMediaTitleInImage
                                            :
                                            classes.topCardMediaTitleBelowImage,
                                    }}
                                    style={{
                                        backgroundSize: imageDoContain ? 'contain' : 'cover',
                                        height: imageHeight !== undefined && imageWidth !== undefined && !isNaN(imageHeight / imageWidth * client.viewportWidth)
                                            ?
                                            imageHeight / imageWidth * client.viewportWidth
                                            :
                                            300,
                                        backgroundColor: imageBackgroundColor?.css,
                                    }}
                                />
                                {
                                    displayDivider &&
                                    <Divider/>
                                }
                            </div>
                        }
                        {
                            store.configurationStore
                                ?
                                <>
                                    {
                                        store.isOpenedInConfigurationMode &&
                                        <div
                                            style={{
                                                padding: '20px 20px 10px 20px',
                                                columnWidth: 400,
                                                columnGap: 20,
                                            }}
                                        >
                                            <ProductInformation
                                                store={store}
                                            />
                                        </div>
                                    }
                                    <ProductConfiguration
                                        store={store.configurationStore}
                                    />
                                </>
                                :
                                <>
                                    <CardContent
                                        style={{
                                            columnWidth: 400,
                                            columnGap: 20,
                                            paddingTop: 20,
                                            paddingBottom: 20,
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                        }}
                                    >
                                        <ProductInformation
                                            store={store}
                                        />
                                    </CardContent>
                                </>
                        }
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*<ActionButton*/}
                    {/*icon="arrow_back"*/}
                    {/*name="Terug"*/}
                    {/*onClick={store.close}*/}
                    {/*secondaryIcon="arrow_back_ios"*/}
                    {/*/>*/}
                    {/*</Grid>*/}
                </Grid>
            </div>;
        });
    };

function useProductImageDimensions(store: ProductStore)
{
    const [imageHeight, setImageHeight] = useState<number | undefined>();
    const [imageWidth, setImageWidth] = useState<number | undefined>();
    const imageURL = useObserver(() => {
        const url = store.product.imageUrl ?? store.product.category.imageUrl;
        return url !== undefined
            ? getFileUrl(url)
            : undefined;
    });
    const image = useObserver(() => {
        if (imageURL !== undefined)
        {
            const image = new Image();
            image.src = imageURL;
            return image;
        }
    });
    useEffect(
        () => {
            if (image !== undefined)
            {
                image.onload = () => {
                    setImageHeight(image.height);
                    setImageWidth(image.width);
                };
            }
        },
        [image],
    );
    return {imageHeight, imageWidth};
}
