import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC, useContext, useMemo } from 'react';
import { HateoasStoryPost } from '../../../../../../Api/v3/model/story_post/HateoasStoryPost';
import { StoriesPlayerContext } from '../../../context/StoriesPlayerContext';
import { StoryPlayerContext } from '../../../context/StoryPlayerContext';

const GracePeriod = 100;
const MinDurationMultiplePosts = 7000;
const MinDurationSinglePost = 5000;

const useStyles = makeStyles({
	progress: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,

		backgroundColor: 'white',
		borderRadius: '1.5px',
	},
	progressActive: {
		animation: `$progressActive ${MinDurationSinglePost}ms linear`,
	},
	progressCompleted: {
		width: '100%',
	},
	root: {
		backgroundColor: 'rgba(255, 255, 255, 0.4)',
		borderRadius: '1.5px',
		flexGrow: 1,
		height: 3,
		position: 'relative',
	},
	'@keyframes progressActive': {
		'0%': {
			width: 0,
		},
		'100%': {
			width: '100%',
		},
	},
});

interface StoryPlayerPostProgressProps
{
	post: HateoasStoryPost;
	active: boolean;
	completed: boolean;
	onContinue: () => void;
}

export const StoryPlayerPostProgress: FC<StoryPlayerPostProgressProps> =
	({
		post,
		active,
		completed,
		onContinue,
	}) =>
	{
		const {isPaused} = useContext(StoriesPlayerContext);
		const {isPlaying} = useContext(StoryPlayerContext);

		const duration = useMemo(
			() =>
			{
				if (post.content.overlay?.type === 'Video')
				{
					return Math.max(
						post.content.elements.length > 1
							? MinDurationMultiplePosts
							: MinDurationSinglePost,
						post.content.overlay.durationInMilliseconds - GracePeriod,
					);
				}
				else
				{
					return MinDurationMultiplePosts;
				}
			},
			[post.content.elements.length, post.content.overlay],
		);

		const classes = useStyles();

		return <div
			className={classes.root}
		>
			<div
				className={
					clsx(
						classes.progress,
						{
							[classes.progressActive]: active,
							[classes.progressCompleted]: completed,
						},
					)
				}
				style={{
					animationDuration: `${duration}ms`,
					animationPlayState: isPlaying && !isPaused
						? undefined
						: 'paused',
				}}
				onAnimationEnd={
					() =>
					{
						if (active)
						{
							onContinue();
						}
					}
				}
			/>
		</div>;
	};
