// @ts-nocheck
/* eslint-disable */
import * as chroma from 'chroma-js';
import { makeAutoObservable } from 'mobx';
import { BrandingInformation } from '../../Api/Business/BrandingInformation';
import { Color } from '../../Api/Other/Color';

import background from '../../Resources/DefaultBranding/background.jpg';
import inlineLogo from '../../Resources/DefaultBranding/butlaroo-inline-white-blgrey.png';
import logo from '../../Resources/DefaultBranding/butlaroo-withsub.png';
import { getFileUrl } from '../../Util/Api/Resources/getFileUrl';
import { BoxedReaction } from '../../Util/Reaction/BoxedReaction';
import { BusinessProvider } from './BusinessProvider';
import { computeContrastTextColor } from './computeContrastTextColor';

export class BrandingService
{
    // ------------------------- Properties -------------------------

    // private static DEFAULT_BACKGROUND_IMAGE_URL = '/drive0/business/logo/5b76f1bc-1629-4794-827a-6d7175630543/dfcba974-0258-484f-aece-a469253f38ae.jpg';
    private static DEFAULT_BACKGROUND_IMAGE_URL = background;

    private static DEFAULT_BACKGROUND_IMAGE_BLUR = undefined;

    private static DEFAULT_LOGO_IMAGE_URL = logo;

    private static DEFAULT_INLINE_LOGO_IMAGE_URL = inlineLogo;

    private static DEFAULT_BACKGROUND_COLOR = new Color(0, 0, 0, 0.8);

    private static DEFAULT_APP_BAR_OPACITY = 0.9;

    private static DEFAULT_TINT_COLOR = new Color(255, 186, 18, 1);

    private static DEFAULT_TINT_CONTRAST_TEXT_COLOR = new Color(255, 255, 255, 0.9);

    private static DEFAULT_TOP_BAR_CONTRAST_TEXT_COLOR = new Color(255, 255, 255, 0.9);

    private static DEFAULT_ERROR_COLOR = new Color(244, 67, 54);

    private businessProvider: BusinessProvider;

    private averageColorOfBackgroundImage: Color | undefined;

    private static backgroundImageReaction: BoxedReaction = BoxedReaction.create();

    // ------------------------ Constructor -------------------------

    constructor(
        businessProvider: BusinessProvider
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });

        this.businessProvider = businessProvider;

        BrandingService.backgroundImageReaction
            .define(
                () => this.backgroundImageUrl,
                () => {
                    this.updateAverageColorOfBackgroundImage();
                },
                false,
                {
                    fireImmediately: true,
                }
            );
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get backgroundColor(): Color
    {
        return this.brandingInformation.backgroundColor !== undefined
            ?
            this.brandingInformation.backgroundColor
            :
            BrandingService.DEFAULT_BACKGROUND_COLOR;
    }

    get backgroundImageUrl(): string
    {
        return this.brandingInformation.backgroundImageUrl !== undefined
            ?
            getFileUrl(this.brandingInformation.backgroundImageUrl)
            :
            BrandingService.DEFAULT_BACKGROUND_IMAGE_URL;
    }

    get backgroundImageBlur(): number | undefined
    {
        return this.brandingInformation.backgroundImageBlur
            ?? BrandingService.DEFAULT_BACKGROUND_IMAGE_BLUR;
    }

    get appBarOpacity(): number
    {
        return (this.brandingInformation.appBarOpacity !== undefined && this.brandingInformation.appBarOpacity !== null)
            ?
            this.brandingInformation.appBarOpacity
            :
            BrandingService.DEFAULT_APP_BAR_OPACITY;
    }

    get topBarColor(): Color
    {
        return this.brandingInformation.topBarColor !== undefined
            ?
            this.brandingInformation.topBarColor
            :
            this.tintColor.withAlpha(this.appBarOpacity);
    }

    get logoImageUrl(): string
    {
        return this.brandingInformation.logoImageUrl !== undefined
            ?
            getFileUrl(this.brandingInformation.logoImageUrl)
            :
            BrandingService.DEFAULT_LOGO_IMAGE_URL;
    }

    get logoInlineImageUrl(): string
    {
        return this.brandingInformation.logoInlineImageUrl != undefined
            ?
            getFileUrl(this.brandingInformation.logoInlineImageUrl)
            :
            (
                this.brandingInformation.logoImageUrl !== undefined
                    ?
                    getFileUrl(this.brandingInformation.logoImageUrl)
                    :
                    BrandingService.DEFAULT_INLINE_LOGO_IMAGE_URL
            );
    }

    get tintColor(): Color
    {
        return this.brandingInformation.tintColor != undefined
            ?
            this.brandingInformation.tintColor
            :
            BrandingService.DEFAULT_TINT_COLOR;
    }

    get secondaryColor(): Color
    {
        return this.brandingInformation.secondaryColor != undefined
            ?
            this.brandingInformation.secondaryColor
            :
            this.tintColor;
    }

    get errorColor(): Color
    {
        return this.brandingInformation.errorColor != undefined
            ?
            this.brandingInformation.errorColor
            :
            BrandingService.DEFAULT_ERROR_COLOR;
    }

    get backgroundContrastTextColor(): Color
    {
        return this.brandingInformation.backgroundContrastTextColor !== undefined
            ?
            this.brandingInformation.backgroundContrastTextColor
            :
            (
                this.averageColorOfBackground !== undefined
                    ?
                    BrandingService.computeContrastTextColorFor(this.averageColorOfBackground)
                    :
                    BrandingService.computeContrastTextColorFor(this.backgroundColor)
            );
    }

    get tintContrastTextColor(): Color
    {
        return this.brandingInformation.tintContrastTextColor !== undefined
            ?
            this.brandingInformation.tintContrastTextColor
            :
            (
                this.brandingInformation.tintColor != undefined
                    ?
                    BrandingService.computeContrastTextColorFor(this.brandingInformation.tintColor)
                    :
                    BrandingService.DEFAULT_TINT_CONTRAST_TEXT_COLOR
            );
    }

    get topBarContrastTextColor(): Color
    {
        return this.topBarColor !== undefined
                    ?
                    BrandingService.computeContrastTextColorFor(this.topBarColor)
                    :
                    BrandingService.DEFAULT_TOP_BAR_CONTRAST_TEXT_COLOR;
    }

    get secondaryContrastTextColor(): Color
    {
        return this.brandingInformation.secondaryContrastTextColor !== undefined
            ?
            this.brandingInformation.secondaryContrastTextColor
            :
            BrandingService.computeContrastTextColorFor(this.secondaryColor);
    }

    get errorContrastTextColor(): Color
    {
        return this.brandingInformation.errorContrastTextColor !== undefined
            ?
            this.brandingInformation.errorContrastTextColor
            :
            BrandingService.computeContrastTextColorFor(this.errorColor);
    }

    get logoSubtitle(): string | undefined
    {
        return this.brandingInformation.logoSubtitle;
    }

    get averageColorOfBackground(): Color | undefined
    {
        if (this.averageColorOfBackgroundImage !== undefined) {
            const chromaColorRgb = chroma
                .mix(
                    this.averageColorOfBackgroundImage.chroma,
                    this.backgroundColor.chroma,
                    this.backgroundColor.a,
                    'rgb'
                )
                .rgb();
            return new Color(
                chromaColorRgb[0],
                chromaColorRgb[1],
                chromaColorRgb[2],
                1,
            );
        } else {
            return undefined;
        }
    }

    public get shouldUseSecondaryColorOnMenu(): boolean
    {
        if (this.averageColorOfBackground !== undefined) {
            const contrastBetweenPrimaryColorAndBackground = chroma
                .contrast(
                    this.averageColorOfBackground.chroma,
                    this.tintColor.chroma
                );
            return contrastBetweenPrimaryColorAndBackground < 4.5;
        } else {
            return false;
        }
    }

    get hasLogoInlineImage(): boolean
    {
        return this.brandingInformation.logoInlineImageUrl !== undefined;
    }

    private get brandingInformation(): BrandingInformation
    {
        const business = this.businessProvider.business;
        return business !== undefined && business.brandingInformation !== undefined
            ?
            this.businessProvider.business.brandingInformation
            :
            new BrandingInformation();
    }

    getColor(intention: undefined): undefined;
    getColor(intention: 'primary' | 'secondary' | 'error'): Color;
    public getColor(intention: 'primary' | 'secondary' | 'error' | undefined)
    {
        switch (intention)
        {
            case 'error':
                return this.errorColor;
            case 'primary':
                return this.tintColor;
            case 'secondary':
                return this.secondaryColor;
            default:
                return undefined;
        }
    }

    getTextContrastColor(intention: undefined): undefined;
    getTextContrastColor(intention: 'primary' | 'secondary' | 'error'): Color;
    public getTextContrastColor(intention: 'primary' | 'secondary' | 'error' | undefined)
    {
        switch (intention)
        {
            case 'error':
                return this.errorContrastTextColor;
            case 'primary':
                return this.tintContrastTextColor;
            case 'secondary':
                return this.secondaryContrastTextColor;
            default:
                return undefined;
        }
    }

    get isBusinessInformationInBackground()
    {
        return this.businessProvider.business.brandingInformation?.isBusinessInformationInBackground ?? false;
    }

    // -------------------------- Actions ---------------------------

    private setAverageColorOfBackgroundImage(averageColorOfBackgroundImage: Color): void
    {
        this.averageColorOfBackgroundImage = averageColorOfBackgroundImage;
    }

    // ------------------------ Public logic ------------------------

    public static get defaultBackgroundImageUrl(): string
    {
        return getFileUrl(BrandingService.DEFAULT_BACKGROUND_IMAGE_URL);
    }

    public static get defaultLogoImageUrl(): string
    {
        return BrandingService.DEFAULT_LOGO_IMAGE_URL;
    }

    // ----------------------- Private logic ------------------------

    public static computeContrastTextColorFor(color: Color): Color
    {
        const rgba = computeContrastTextColor(color.chroma).rgba(true);
        return new Color(rgba[0], rgba[1], rgba[2], rgba[3]);
    }

    updateAverageColorOfBackgroundImage(): void
    {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = () => {
            const averageColorOfBackgroundImage = BrandingService.getAverageRGB(image);
            if (averageColorOfBackgroundImage !== undefined)
            {
                this.setAverageColorOfBackgroundImage(averageColorOfBackgroundImage);
            }
        };
        image.src = this.backgroundImageUrl;
    }

    /**
     * Function for calculating the average {@link Color} of an image
     * Source: https://stackoverflow.com/a/2541680/2225070
     * @param imgEl The image for which to calculate the average color
     */
    private static getAverageRGB(imgEl: HTMLImageElement): Color | undefined
    {

        const blockSize = 5; // only visit every 5 pixels
        const canvas = document.createElement('canvas');
        const context = canvas.getContext && canvas.getContext('2d');

        if (!context) {
            return undefined;
        }

        const height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
        const width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

        context.drawImage(imgEl, 0, 0);

        try {
            const imageData = context.getImageData(0, 0, width, height);
            const length = imageData.data.length;

            let i = -4;
            let count = 0;
            const averageColor = new Color(0, 0, 0, 1);
            while ( (i += blockSize * 4) < length ) {
                count++;
                averageColor.r += imageData.data[i];
                averageColor.g += imageData.data[i+1];
                averageColor.b += imageData.data[i+2];
            }

            // ~~ used to floor values
            averageColor.r = ~~(averageColor.r/count);
            averageColor.g = ~~(averageColor.g/count);
            averageColor.b = ~~(averageColor.b/count);

            return averageColor;
        } catch(e) {
            return undefined;
        }
    }
}
