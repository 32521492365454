import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { postJson } from '../../../Api/v3/fetch/postJson';
import { useFetch } from '../../../Api/v3/fetch/useFetch';
import { CreateStoryPostViewRequest } from '../../../Api/v3/model/story_post_view/CreateStoryPostViewRequest';
import { HateoasStoryPostView } from '../../../Api/v3/model/story_post_view/HateoasStoryPostView';
import { StoryWithPosts } from '../../Page/Business/StoriesPlayer/Model/StoryWithPosts';
import { StoryPostContextProvider } from '../context/StoryPostContextProvider';
import { StoryPlayerContext } from './context/StoryPlayerContext';
import { StoryPlayerHeader } from './header/StoryPlayerHeader';
import { ActionContext } from './post_viewer/action/ActionContext';
import { StoryPostViewer } from './post_viewer/StoryPostViewer';

const useStyles = makeStyles(theme => ({
	root: {
		borderRadius: 2 * theme.shape.borderRadius,
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
		height: '100%',
		userSelect: 'none',
	},
	rootFullscreen: {
		borderRadius: 0,
	},
}));

interface StoryPlayerProps
{
	story: StoryWithPosts;
	actionContext: ActionContext;
	active: boolean;
	fullscreen: boolean;
	onPrevious?: () => void;
	onNext: () => void;
	onClose: () => void;
}

export const StoryPlayer: FC<StoryPlayerProps> =
	({
		story,
		actionContext,
		active,
		fullscreen,
		onPrevious,
		onNext,
		onClose,
	}) =>
	{
		const fetch = useFetch();
		const {openPostIdx, onChangeOpenPostIdx, onChangeIsPlaying} = useContext(StoryPlayerContext);

		const [postViewDate, setPostViewDate] = useState(new Date());

		const saveView = useCallback(
			async () =>
			{
				const post = story.posts[openPostIdx];

				await postJson<HateoasStoryPostView, CreateStoryPostViewRequest>(
					fetch,
					'/storyPostViews',
					{
						postId: post.id,
						startDate: postViewDate.toISOString(),
						endDate: new Date().toISOString(),
					},
				);
			},
			[story.posts, openPostIdx, fetch, postViewDate],
		);

		const handleClose = useCallback(
			async () =>
			{
				onClose();
				await saveView();
			},
			[onClose, saveView],
		);

		const changePost = useCallback(
			async (nextPostIdx: number) =>
			{
				setPostViewDate(new Date());
				onChangeOpenPostIdx(nextPostIdx);

				await saveView();
			},
			[onChangeOpenPostIdx, saveView],
		);

		const gotoPreviousPost = useCallback(
			async () =>
			{
				if (openPostIdx === 0)
				{
					if (onPrevious !== undefined)
					{
						onPrevious();
						onChangeIsPlaying(false);
						await saveView();
					}
				}
				else
				{
					onChangeIsPlaying(false);
					await changePost(openPostIdx - 1);
				}
			},
			[changePost, onChangeIsPlaying, onPrevious, openPostIdx, saveView],
		);

		const gotoNextPost = useCallback(
			async () =>
			{
				onChangeIsPlaying(false);

				if (openPostIdx === story.posts.length - 1)
				{
					onNext();
					await saveView();
				}
				else
				{
					await changePost(
						Math.min(
							openPostIdx + 1,
							story.posts.length - 1,
						),
					);
				}
			},
			[changePost, onChangeIsPlaying, onNext, openPostIdx, saveView, story.posts.length],
		);

		useEffect(
			() =>
			{
				if (active)
				{
					const listener =
						(event: KeyboardEvent) =>
						{
							if (event.key === 'ArrowLeft')
							{
								// noinspection JSIgnoredPromiseFromCall
								gotoPreviousPost();
							}
							else if (event.key === 'ArrowRight')
							{
								// noinspection JSIgnoredPromiseFromCall
								gotoNextPost();
							}
						};
					document.addEventListener(
						'keydown',
						listener,
					);

					return () =>
						document.removeEventListener(
							'keydown',
							listener,
						);
				}
			},
			[gotoPreviousPost, gotoNextPost, active],
		);

		const classes = useStyles();

		return <div
			className={
				clsx(
					classes.root,
					{
						[classes.rootFullscreen]: fullscreen,
					},
				)
			}
		>
			{
				new Array(story.posts.length)
					.fill(0)
					.map(
						(_, idx) =>
							<StoryPostContextProvider
								key={story.posts[idx].id}
								story={story}
								postIdx={idx}
								active={active && openPostIdx === idx}
							>
								<StoryPostViewer
									content={story.posts[idx].content}
									actionContext={actionContext}
									onNavigateToPreviousPost={gotoPreviousPost}
									onNavigateToNextPost={gotoNextPost}
									style={{
										display: idx === openPostIdx
											? undefined
											: 'none',
									}}
								/>
							</StoryPostContextProvider>,
					)
			}
			<StoryPlayerHeader
				story={story}
				active={active}
				postIdx={openPostIdx}
				onChangePostIdx={onChangeOpenPostIdx}
				onClose={handleClose}
				onFinish={onNext}
			/>
		</div>;
	};
