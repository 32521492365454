import { BaseStore } from '@intentic/ts-foundation';
import { Decimal } from 'decimal.js';
import { action, computed, flow, makeObservable, observable, ObservableMap } from 'mobx';
import { Announcement } from '../../../../Api/Business/Announcement';
import { Business } from '../../../../Api/Business/Business';
import { Place } from '../../../../Api/Business/Place';
import { Product } from '../../../../Api/Product/Product';
import { ProductCategory } from '../../../../Api/Product/ProductCategory';
import { ProductConfiguration } from '../../../../Api/Product/ProductConfiguration';
import { ProductFee } from '../../../../Api/Product/ProductFee';
import { TimeSchedule } from '../../../../Api/Util/time-schedule/TimeSchedule';
import { isTrueAtInCache } from '../../../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import { Bridge } from '../../../../Bridge/Bridge';
import { Navigator } from '../../../../Bridge/Navigator/Navigator';
import { ProfileService } from '../../../../Service/ProfileService';
import { nonNullish } from '../../../../Util/nonNullish';
import { getProductCategoryAndAllParentProductCategoryIds } from '../../../../Util/Product/getProductCategoryAndAllParentProductCategoryIds';
import { BusinessStore } from '../BusinessStore';
import { getStoriesFilteredByLocation } from '../StoriesPlayer/Api/getStoriesFilteredByLocation';
import { StoryWithPosts } from '../StoriesPlayer/Model/StoryWithPosts';
import { ProductConfigurationStore } from './ProductConfiguration/ProductConfigurationStore';

export class ProductStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    readonly bridge: Bridge;
    private readonly navigator: Navigator;
    readonly profileService: ProfileService;
    readonly businessStore: BusinessStore;
    readonly business: Business;
    readonly place: Place;
    readonly product: Product;
    amountToAdd: number;
    public readonly onOrder: (productConfiguration: ProductConfiguration, amount: number) => Promise<void>;
	private readonly onAfterOrder: (productConfiguration: ProductConfiguration) => Promise<void>;
    private readonly onClose: () => Promise<void>;
	private readonly recommendProducts: boolean;

    allergenOrNutritionFlagToShowTooltipFor: string | undefined;
    allergenTooltipTimer: any | undefined;

    isOpenedInConfigurationMode: boolean;
    isInConfigurationMode: boolean;
    private readonly categoryById: Map<number, ProductCategory>;
    private readonly timeScheduleById: ObservableMap<number, TimeSchedule>;
    private readonly showStories: boolean;

    // ------------------------ Constructor -------------------------

    constructor(
        bridge: Bridge,
        profileService: ProfileService,
        businessStore: BusinessStore,
        business: Business,
        place: Place,
        product: Product,
        onOrder: (productConfiguration: ProductConfiguration, amount: number) => Promise<void>,
        categoryById: Map<number, ProductCategory>,
        timeScheduleById: ObservableMap<number, TimeSchedule>,
		onAfterOrder: (productConfiguration: ProductConfiguration) => Promise<void>,
        onClose: () => Promise<void>,
        recommendProducts: boolean,
        openInConfigurationMode: boolean,
        showStories: boolean,
    )
    {
        super();

        makeObservable<ProductStore, 'showValidation' | 'allergenAnnouncements' | 'category'>(
            this,
            {
                amountToAdd: observable,
                allergenOrNutritionFlagToShowTooltipFor: observable,
                allergenTooltipTimer: observable,
                isOpenedInConfigurationMode: observable,
                isInConfigurationMode: observable,
                currentlyActiveProductFees: computed,
                price: computed,
                priceMayVary: computed,
                showPriceDisplay: computed,
                priceLabel: computed,
                isConfigurable: computed,
                showOrderButton: computed,
                orderButtonHasErrorColor: computed,
                showValidation: computed,
                orderButtonIsDisabled: computed,
                productConfigurationIsValid: computed,
                isInStock: computed,
                allergenAnnouncements: computed,
                stories: computed,
                announcements: computed,
                category: computed,
                sanitizedHtmlDescription: computed,
                configurationStore: computed,
                close: action.bound,
                closeStore: action.bound,
                openConfigurationMode: action.bound,
                closeConfigurationMode: action.bound,
                orderWithoutConfiguration: action.bound,
                onClickAllergenOrNutritionFlag: action.bound,
                setAllergenOrNutritionFlagToShowTooltipFor: action.bound,
                setAmount: action.bound,
            },
        )

        this.bridge = bridge;
        this.navigator = bridge.navigator;
        this.profileService = profileService;
        this.businessStore = businessStore;
        this.business = business;
        this.place = place;
        this.product = product;
        this.amountToAdd = 1;
        this.onOrder = onOrder;
        this.categoryById = categoryById;
        this.timeScheduleById = timeScheduleById;
		this.onAfterOrder = onAfterOrder;
		this.onClose = onClose;
		this.recommendProducts = recommendProducts;
		this.isOpenedInConfigurationMode = openInConfigurationMode;
        this.isInConfigurationMode = openInConfigurationMode;
        this.showStories = showStories;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get currentlyActiveProductFees(): ProductFee[]
    {
        const pointInTime = this.bridge.clockService.currentMinute;

        return this
            .product
            .fees
            .filter(fee =>
            {
                if (fee.activationTimeScheduleId === undefined)
                    return true;
                else
                {
                    const timeSeries = this
                        .timeScheduleById
                        .get(fee.activationTimeScheduleId)
                        ?.booleanTimeSeries;

                    return timeSeries === undefined
                        ? false
                        : isTrueAtInCache(timeSeries, pointInTime);
                }
            });
    }

    get price(): Decimal
    {
        let price: Decimal;
        if (this.configurationStore)
        {
            price = this.configurationStore.price;
        }
        else
        {
            price = this.product.minPrice;
        }
        return price;
    }

    get priceMayVary(): boolean
    {
        let priceMayVary = this.product.hasPriceAlteration;
        if (this.configurationStore)
        {
            priceMayVary = false;
        }
        return priceMayVary;
    }

    get showPriceDisplay(): boolean
    {
        return !(this.product.isPriceHidden(this.price) && !this.priceMayVary);
    }

    get priceLabel(): string | undefined
    {
        let hasPriceAlteration = this.product.hasPriceAlteration;

        if (this.configurationStore)
        {
            hasPriceAlteration = false;
        }

        if (this.showPriceDisplay)
        {
            if (hasPriceAlteration)
            {
                return `${this.bridge.localizer.translate('Generic-From')} `;
            }
            else
            {
                if (this.configurationStore)
                {
                    return `${this.bridge.localizer.translate('Client-ShoppingCart-Total')}: `;
                }
                else
                {
                    return ``;
                }
            }
        }
        else
        {
            return undefined;
        }
    }

    get isConfigurable(): boolean
    {
        return this.product.isConfigurable;
    }

    /**
     * If the ordering of {@link product} is supported.
     */
    public get showOrderButton(): boolean
    {
        const now = this.bridge.clockService.currentMinute;

        const placeOrderProductsTimeSchedule = this.place.orderProductsTimeScheduleId === undefined
            ? undefined
            : this.timeScheduleById.get(this.place.orderProductsTimeScheduleId);

        const timeSeries =
            [
                this.product.orderableAt,
                placeOrderProductsTimeSchedule?.booleanTimeSeries,
            ]
                .filter(nonNullish);

        return this.product.isAvailableForOrdering
            && (
                this.place.timeSchedulesIgnored ||
                this.place.orderInFutureSupport !== 'DISALLOWED' ||
                timeSeries.every(timeSeries => isTrueAtInCache(timeSeries, now))
            )
            && this.place.isOrderProductSupported;
    }

    public get orderButtonHasErrorColor(): boolean
    {
        return this.showOrderButton
            && (
                (this.showValidation && !this.productConfigurationIsValid)
                || !this.isInStock
            );
    }

    private get showValidation(): boolean
    {
        return this.configurationStore !== undefined
            && this.configurationStore.showValidation;
    }

    public get orderButtonIsDisabled(): boolean
    {
        return this.showOrderButton
            && (
                (this.showValidation && !this.productConfigurationIsValid)
                || !this.isInStock
            )
    }

    get productConfigurationIsValid(): boolean
    {
        return this.configurationStore === undefined
            || this.configurationStore.isValid;
    }

    get isInStock(): boolean
    {
        if (!this.isConfigurable)
        {
            return this.product.quantity === undefined
                || this.product.quantity === null
                || this.product.quantity >= 1;
        }
        else
        {
            if (this.product.quantity !== undefined
                && this.product.quantity !== null
                && this.product.quantity === 0)
            {
                return false;
            }

            return this.configurationStore === undefined || this.configurationStore.isInStock;
        }
    }

    private get allergenAnnouncements(): Announcement[]
    {
        const localizer = this.bridge.localizer;
        return this.product
            .allergens
            .filter(allergen => this.profileService.hasAllergen(allergen))
            .map(allergen => {
                const allergenName = localizer.translate(`Allergens-${allergen.charAt(0).toUpperCase().concat(allergen.substr(1))}`);
                const casedAllergenName = localizer.capitalizeNouns
                    ?
                    allergenName
                    :
                    allergenName.toLowerCase();
                const text = localizer.translate(`Allergens-ThisProductContains`, casedAllergenName);
                return new Announcement(
                    undefined,
                    'danger',
                    text,
                    true,
                    true,
                    true
                );
            });
    }

    get stories(): StoryWithPosts[]
    {
        if (this.showStories)
        {
            return getStoriesFilteredByLocation(
                {
                    type: 'Product',
                    productId: this.product.uuid,
                },
                this.businessStore.stories,
            );
        }
        else
        {
            return [];
        }
    }

    get announcements(): Announcement[]
    {
        const allCategories = Array.from(this.categoryById.values());
        const relevantCategoryIds = getProductCategoryAndAllParentProductCategoryIds(this.category, allCategories);

        return [
            ...this.ageVerificationAnnouncement
                ? [this.ageVerificationAnnouncement]
                : [],
            ...this.allergenAnnouncements,
            ...this.businessStore.productAnnouncements
                .filter(announcement => {
                    if (announcement.isAssignedToProduct)
                        return announcement.productIds.includes(this.product.id);
                    else if (announcement.isAssignedToProductCategory)
                        return announcement.productCategoryIds.some(id => relevantCategoryIds.indexOf(id) !== -1);
                    else
                        return true;
                }),
        ];
    }

    get ageVerificationAnnouncement(): Announcement | undefined
    {
        if (this.product.needsAgeVerification
            && this.place.isAgeVerificationRequiredForRestrictedProducts)
        {
            return new Announcement(
                undefined,
                'danger',
                this.bridge.localizer.translate('AgeVerification-Voucher-DescriptionInProduct'),
                true,
                true,
                true
            );
        }
        else
        {
            return undefined;
        }
    }

    private get category(): ProductCategory
    {
        return this.product.category;
    }

    get sanitizedHtmlDescription(): string | undefined
    {
        return this.product.sanitizedHtmlDescription;
    }

    // --------------------------- Stores ---------------------------

    get configurationStore(): ProductConfigurationStore | undefined
    {
        return this.isInConfigurationMode
            ?
            new ProductConfigurationStore(
                this.bridge,
                this.product,
                this.business.productCurrencyCode,
                () => this.close(),
                this.recommendProducts,
            )
            :
            undefined;
    }

    // -------------------------- Actions ---------------------------

    close(): Promise<void>
    {
        const thiss = this;

        return flow(function * () {
			if (thiss.isInConfigurationMode)
			{
			    if (thiss.isOpenedInConfigurationMode)
                {
                    yield thiss.closeStore();
                }
			    else
                {
                    thiss.closeConfigurationMode();
                }
			}
			else
			{
				yield thiss.closeStore();
			}
		})();
    }

    closeStore(): Promise<void>
    {
        const thiss = this;

        return flow(function * () {
            yield thiss.onClose();
        })();
    }

    openConfigurationMode(): void
    {
        this.isInConfigurationMode = true;
    }

    closeConfigurationMode(): void
    {
        this.isInConfigurationMode = false;
    }

    orderWithoutConfiguration(amount: number): Promise<void>
    {
        return this.onOrder(new ProductConfiguration(this.product, []), amount);
    }

    onClickAllergenOrNutritionFlag(allergen: string, timeout: boolean = true): void
    {
        if (this.allergenTooltipTimer !== undefined) {
            clearTimeout(this.allergenTooltipTimer);
        }
        if (this.allergenOrNutritionFlagToShowTooltipFor === allergen) {
            this.setAllergenOrNutritionFlagToShowTooltipFor(undefined);
        } else {
            this.setAllergenOrNutritionFlagToShowTooltipFor(allergen);
            if (timeout) {
                this.allergenTooltipTimer = setTimeout(
                    () => {
                        this.setAllergenOrNutritionFlagToShowTooltipFor(undefined);
                        this.allergenTooltipTimer = undefined;
                    },
                    850
                )
            }
        }
    }

    setAllergenOrNutritionFlagToShowTooltipFor(allergenToShowTooltipFor: string | undefined): void
    {
        this.allergenOrNutritionFlagToShowTooltipFor = allergenToShowTooltipFor;
    }

    setAmount(amount: number)
    {
        this.amountToAdd = amount;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
