import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../../../../RootContext';
import { RequestBillIcon } from './Icon/RequestBillIcon';
import { TopContentButton } from './TopContentButton';

interface RequestBillButtonProps
{
	onClick: () => void;
	onClickLegacy: () => void;
	useLegacy: boolean;
}

export const RequestBillButton: FC<RequestBillButtonProps> =
	(
		{
			onClick,
			onClickLegacy,
			useLegacy,
		},
	) =>
	{
		const {brandingService} = useRootContext(true);
		const iconColor = useObserver(() => brandingService.tintContrastTextColor.css);
		const translate = useTranslate();

		return <TopContentButton
			icon={
				<RequestBillIcon
					color={iconColor}
				/>
			}
			label={translate(useLegacy ? 'Client-Order-RequestBill' : 'Client-Order-OpenBill')}
			onClick={useLegacy ? onClickLegacy : onClick}
		/>;
	};
