import { FC, PropsWithChildren, useMemo } from 'react';
import { FetchHeadersContext } from './FetchHeadersContext';
import { useFetchHeaders } from './useFetchHeaders';

interface FetchHeadersProviderProps
{
	headers: HeadersInit;
}

export const FetchHeadersProvider: FC<PropsWithChildren<FetchHeadersProviderProps>> =
	({
		headers,
		children,
	}) =>
	{
		const inheritedHeaders = useFetchHeaders();
		const combinedHeaders =
			useMemo(
				() => ({
					...inheritedHeaders,
					...headers,
				}),
				[
					inheritedHeaders,
					headers,
				],
			);

		return <FetchHeadersContext.Provider
			value={combinedHeaders}
		>
			{children}
		</FetchHeadersContext.Provider>;
	};
