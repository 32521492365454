import { FC, useContext } from 'react';
import { BusinessContextRef } from '../Page/Business/BusinessContext';
import { BusinessFetchProvider } from '../Page/Business/BusinessFetchProvider';
import { StoryLoadingContextProvider } from '../story/context/StoryLoadingContextProvider';

export const BusinessScreenContextProvider: FC =
	({
		children,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);

		return <BusinessFetchProvider
			store={businessStore}
		>
			<StoryLoadingContextProvider>
				{children}
			</StoryLoadingContextProvider>
		</BusinessFetchProvider>;
	};
