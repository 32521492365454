import { useObserver } from 'mobx-react-lite';
import { useMemo } from 'react';
import { LanguageEntry } from '../../../Api/v3/model/language_entry/LanguageEntry';
import { useRootContext } from '../../../RootContext';

export function useLocalizedLanguageEntry(entry: LanguageEntry | undefined): string | undefined
{
	const {localizer} = useRootContext(true);

	const translateLanguageEntry = useObserver(() => localizer.translateLanguageEntry);

	return useMemo(
		() =>
			translateLanguageEntry(entry),
		[entry, translateLanguageEntry],
	);
}
