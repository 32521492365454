import { getV3ApiUrl } from '../../../Util/Api/Resources/getV3ApiUrl';

export function getInitializedRequestInfo(
	input: RequestInfo,
	params?: Record<string, string>
): RequestInfo
{
	if (typeof input === 'string')
	{
		const url =
			input.startsWith('http')
				? new URL(input)
				: new URL(getV3ApiUrl(input));

		if (params !== undefined)
		{
			Object.entries(params)
				.forEach(
					([ key, value ]) =>
						url.searchParams.set(
							key,
							value
						)
				);
		}

		return url.toString();
	}
	else
	{
		return input;
	}
}
