import { QrCodeParser } from '@intentic/qr-reader-web';
import { BaseStore } from '@intentic/ts-foundation';
import { addSeconds, isAfter } from 'date-fns';
import { toSeconds } from 'iso8601-duration';
import { action, computed, IObservableValue, makeObservable, observable } from 'mobx';
import { Account } from '../../../Api/Account/Account';
import { Business } from '../../../Api/Business/Business';
import { HashData, HashDataProfile } from '../../../Api/Business/HashData';
import { OrderDestinationAddress } from '../../../Api/Business/OrderDestinationAddress';
import { Place } from '../../../Api/Business/Place';
import { Bridge } from '../../../Bridge/Bridge';
import { ScreenInstantiation } from '../../../Bridge/Navigator/ScreenInstantiation';
import { WebStorage } from '../../../Bridge/Storage/WebStorage';
import { QueryParameters } from '../../../Constants/QueryParameters';
import { Screens } from '../../../Constants/ScreenConstants';
import { StorageVars } from '../../../Constants/StorageConstants';
import { BrandingService } from '../../../Service/BrandingInformation/BrandingService';
import { CurrentPlaceService } from '../../../Service/CurrentPlace/CurrentPlaceService';
import { ScaninService } from '../../../Service/EnteringService/ScaninService';
import { KioskService } from '../../../Service/KioskService/KioskService';
import { LocationService } from '../../../Service/Location/LocationService';
import { OrderService } from '../../../Service/OrderService/OrderService';
import { ProfileService } from '../../../Service/ProfileService';
import { WaiterService } from '../../../Service/UserService/WaiterService';
import { fetch } from '../../../Util/Api';
import { ClockService } from '../../../Util/clock/ClockService';
import { findQueryParameter } from '../../../Util/findQueryParameter';
import { BoxedReaction } from '../../../Util/Reaction/BoxedReaction';
import { StoredVariable } from '../../../Util/StoredVariable';
import { AuthenticationResult } from '../../authentication-provider/AuthenticationResult';
import getPlaceCodeHashFromQrCode from '../../UI/PlaceCodeScanner/getPlaceCodeHashFromQrCode';
import { PlaceCodeScannerState } from '../../UI/PlaceCodeScanner/PlaceCodeScannerState';
import { BusinessStore } from '../Business/BusinessStore';
import { HistoryStore } from '../Business/History/HistoryStore';
import { LoginStore } from '../Login/LoginStore';
import { ManagerStore } from '../Manager/ManagerStore';
import { ManagerView } from '../Manager/ManagerView';
import { ProfileStore } from '../Profile/ProfileStore';
import { ScannerStore } from '../Scanner/ScannerStore';
import { getOrderCommentFromUrl } from './Api/getOrderCommentFromUrl';
import { getOrderCouponFromUrl } from './Api/getOrderCouponFromUrl';
import { getOrderDestinationAddressFromUrl } from './Api/getOrderDestinationAddressFromUrl';
import { getSkipEntranceStoriesFromUrl } from './Api/getSkipEntranceStoriesFromUrl';

export const NoHashValue = '-';

export class EntranceStore extends BaseStore
{
    /*---------------------------------------------------------------*
     *                         Dependencies                          *
     *---------------------------------------------------------------*/

    public readonly clockService: ClockService;
    public readonly orderService: OrderService;
    public readonly profileService: ProfileService;
    public readonly brandingService: BrandingService;
    public readonly waiterService = new WaiterService();
    public readonly bridge: Bridge;
    public scannerStore: ScannerStore<BusinessStore>;
    private readonly locationService: LocationService;
    private readonly currentPlaceService: CurrentPlaceService;
    private readonly profileStore: ProfileStore;
    private readonly historyStore: HistoryStore;
    private readonly scaninService: ScaninService;
    private readonly verifyAccount: (account: Account) => Promise<void>;
    private readonly authenticationResult: AuthenticationResult;
    private readonly storage: WebStorage | undefined;

    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    static readonly exitBusinessOnIdle: BoxedReaction = BoxedReaction.create();

    /**
     * If non-{@code null}, the user interface will not allow the user to leave the {@link Place} that this hash points to
     * without typing in a pin code. Any way the app is loaded, it will go into a session on this {@link Place}. This is
     * used for kiosks and business-issued tablets. This variable is persisted to the local storage. The default value is
     * {@link undefined}, meaning there is no {@link Place} lock.
     */
    hashOfLockedPlace: string | undefined;

    public readonly placeSessionRequired: StoredVariable<boolean>;

    public readonly token: string;

    /**
     * Stored setting if order history should be hidden.
     */
    public readonly orderHistoryHidden: StoredVariable<boolean>;

    /**
     * Override of {@link orderHistoryHidden} for only this page load
     */
    private readonly hideOrderHistoryForSession;

    /**
     * ID of an external notifier relating to the current shopper
     */
    public readonly externalShopperNotificationId?: string;

    /**
     * ID of an external card belonging to the current shopper
     */
    public readonly externalShopperCardId?: string;

    public readonly userProfileHidden: StoredVariable<boolean>;

    public readonly navigateToBusinessRootAfterOrder: StoredVariable<boolean>;

    public readonly clearOrderOptionsAfterOrder: StoredVariable<boolean>;

    public readonly clearShoppingCartAfterReturning: StoredVariable<boolean>;

    public readonly isKioskMode: StoredVariable<boolean>;

    public readonly isTopBarHidden: StoredVariable<boolean>;

    public readonly isMenuOpen: IObservableValue<boolean> = observable.box(false);

    public readonly orderComment?: string;

    public readonly orderDestinationAddress?: OrderDestinationAddress;

    public readonly orderCoupon?: string;

    public readonly skipEntranceStories: boolean;

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor(
        bridge: Bridge,
        clockService: ClockService,
        locationService: LocationService,
        kioskService: KioskService,
        currentPlaceService: CurrentPlaceService,
        brandingService: BrandingService,
        scaninService: ScaninService,
        verifyAccount: (account: Account) => Promise<void>,
        authenticationResult: AuthenticationResult,
        storage: WebStorage
    )
    {
        super();

        makeObservable(
            this,
            {
                hashOfLockedPlace: observable,
                hideOrderHistory: computed,
                startScanning: action.bound,
                onScanFinish: action.bound,
                exitScanner: action.bound,
                resetBusinessAndPlace: action.bound,
                enterBusinessFromHash: action.bound,
                enterBusinessWithStore: action.bound,
                enterLogin: action.bound,
                onLogin: action.bound,
                attemptEnterManager: action.bound,
                enterManager: action.bound,
                parseImage: action.bound,
                initializeHashOfLockedPlace: action.bound,
                changeHashOfLockedPlace: action.bound,
                setHashOfLockedPlace: action.bound,
                pop: action.bound,
                push: action.bound,
                navigateToLockedPlaceIfPresent: action.bound,
                openHistory: action.bound,
                openProfile: action.bound,
                openManagerPanel: action.bound,
                openPlace: action.bound,
                getEffectiveHash: action.bound,
                createBusinessStoreFromHash: action.bound,
                createBusinessStore: action.bound
            },
        )

        this.bridge = bridge;
        this.locationService = locationService;
        this.currentPlaceService = currentPlaceService;
        this.profileService = new ProfileService(bridge.storage);
        this.profileStore = new ProfileStore(
            this.profileService,
            this.pop
        );
        this.storage = storage;

        // Read the token query parameter.
        this.token = findQueryParameter(QueryParameters.ComoToken);

        // Read the showHistory query parameter.
        this.hideOrderHistoryForSession = findQueryParameter(QueryParameters.ShowHistory) === '0';

        // Read the externalShopperNotificationId query parameter.
        this.externalShopperNotificationId = findQueryParameter(QueryParameters.ExternalShopperNotificationId);

        // Read the externalShopperCardId query parameter.
        this.externalShopperCardId = findQueryParameter(QueryParameters.ExternalShopperCardId);

        this.placeSessionRequired = new StoredVariable<boolean>(
            bridge.storage,
            StorageVars.PlaceSessionRequired,
            value => value === 'true' || value === '1',
            value => value ? 'true' : undefined
        );
        this.placeSessionRequired.syncWithStorage();
        this.orderHistoryHidden = new StoredVariable<boolean>(
            bridge.storage,
            StorageVars.OrderHistoryHidden,
            value => value === 'true' || value === '1',
            value => value ? 'true' : undefined
        );
        this.orderHistoryHidden.syncWithStorage();
        this.userProfileHidden = new StoredVariable<boolean>(
            bridge.storage,
            StorageVars.UserProfileHidden,
            value => value === 'true' || value === '1',
            value => value ? 'true' : undefined
        );
        this.userProfileHidden.syncWithStorage();
        this.navigateToBusinessRootAfterOrder = new StoredVariable<boolean>(
            bridge.storage,
            StorageVars.NavigateToBusinessRootAfterOrder,
            value => value === 'true' || value === '1',
            value => value ? 'true' : undefined
        );
        this.navigateToBusinessRootAfterOrder.syncWithStorage();
        this.clearOrderOptionsAfterOrder = new StoredVariable<boolean>(
            bridge.storage,
            StorageVars.ClearOrderOptionsAfterOrder,
            value => value === 'true' || value === '1',
            value => value ? 'true' : undefined
        );
        this.clearOrderOptionsAfterOrder.syncWithStorage();
        this.clearShoppingCartAfterReturning = new StoredVariable<boolean>(
            bridge.storage,
            StorageVars.ClearShoppingCartAfterReturning,
            value => value === 'true' || value === '1',
            value => value ? 'true' : undefined
        );
        this.clearShoppingCartAfterReturning.syncWithStorage();
        this.isKioskMode = kioskService.isKioskMode;
        this.isTopBarHidden = new StoredVariable<boolean>(
            bridge.storage,
            StorageVars.HideTopBar,
            value => value === 'true' || value === '1',
            value => value ? 'true' : undefined
        );
        this.isTopBarHidden.syncWithStorage();
        this.brandingService = brandingService;
        this.clockService = clockService;
        this.orderService = new OrderService(this.bridge.linking);
        this.historyStore = new HistoryStore(
            this.bridge,
            undefined,
            this.brandingService,
            this.orderService,
            undefined,
            undefined,
            this.pop,
            undefined,
            undefined,
            undefined,
            undefined,
            this.pop,
            this.push,
        );
        this.scaninService = scaninService;
        this.verifyAccount = verifyAccount;
        this.authenticationResult = authenticationResult;

        // Fetch parameters from URL because the URL gets rewritten (losing the parameters) upon place entry
        this.orderComment = getOrderCommentFromUrl();
        this.orderDestinationAddress = getOrderDestinationAddressFromUrl();
        this.orderCoupon = getOrderCouponFromUrl();
        this.skipEntranceStories = getSkipEntranceStoriesFromUrl();
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    initialize(): Promise<void>
    {
        // setTimeout(
        //     () =>
        //     {
        //         // this.enterBusinessFromHash('rAp', [], false)
        //         //     .then(businessStore => this.enterBusinessWithStore(businessStore));
        //     },
        //     500);

        this.scannerStore = new ScannerStore<BusinessStore>(
            this.bridge,
            this.brandingService,
            async hash =>
                hash === undefined
                    ? undefined
                    : this.createBusinessStoreFromHash(hash),
            this.onScanFinish,
        );

        this.initializeHashOfLockedPlace();

        return Promise
            .all([
                this.orderService.initialize()
                    .catch(error => {
                        console.error(`${error}`)
                        return Promise.resolve();
                    }),
                this.scaninService.onInitialized,
            ])
            .then(
                () =>
                {
                    if (!this.bridge.client.isRunningOnTv)
                    {
                        this.locationService.start();
                    }
                    return Promise.resolve();
                });
    }

    // -------------------------- Computed --------------------------

    get hideOrderHistory(): boolean
    {
        // Override default behaviour if the order history should be hidden for this specific session
        return this.hideOrderHistoryForSession || this.orderHistoryHidden.value;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    startScanning(): Promise<ScreenInstantiation>
    {
        return this.push(
            Screens.Scanner,
            this.scannerStore
        );
    }

    async onScanFinish(businessStore: BusinessStore): Promise<BusinessStore>
    {
        await this.exitScanner(true);

        this.currentPlaceService.setScannedBusinessAndPlace(
            businessStore.business,
            businessStore.place,
        );

        return this.enterBusinessWithStore(businessStore, false, true);
    }

    exitScanner(force?: boolean): Promise<boolean>
    {
        if (this.bridge.navigator.currentScreenInstance?.screen.id === Screens.Scanner)
        {
            return this.pop(force);
        }
        else
        {
            return Promise.reject();
        }
    }

    resetBusinessAndPlace()
    {
    }

    async enterBusinessFromHash(
        hash: string,
        withLock: boolean,
    ): Promise<BusinessStore | undefined>
    {
        const businessStore = await this.createBusinessStoreFromHash(hash);

        if (businessStore !== undefined)
        {
            this.currentPlaceService.setScannedBusinessAndPlace(
                businessStore.business,
                businessStore.place,
            );

            return this.enterBusinessWithStore(businessStore, withLock, hash !== NoHashValue);
        }
    }

    async enterBusinessWithStore(
        businessStore: BusinessStore,
        withLock: boolean,
        writeHashDataToStorage: boolean,
    ): Promise<BusinessStore>
    {
        this.changeHashOfLockedPlace(
            withLock
                ? businessStore.placeHash
                : this.hashOfLockedPlace,
        );

        if (writeHashDataToStorage)
        {
            this.bridge.storage.set(
                StorageVars.LastPlaceHash,
                businessStore.placeHash,
            );

            this.scaninService.currentScanin.set({
                placeHash: businessStore.placeHash,
            });
        }

        this.bridge.storage.set(
            StorageVars.LastPlaceVisitDate,
            new Date().getTime().toString(),
        );

        await this.bridge.navigator.pushScreen(Screens.Business, businessStore);

        await businessStore.initialize();

        return businessStore;
    }

    async enterLogin(): Promise<LoginStore>
    {
        const loginStore = new LoginStore(
            this.onLogin,
            this.pop,
        );

        await this.push(
            Screens.Login,
            loginStore,
        );

        return loginStore;
    }

    async onLogin(account: Account): Promise<void>
    {
        await this.pop();
        await this.verifyAccount(account);
        await this.attemptEnterManager();
    }

	async attemptEnterManager(view?: ManagerView): Promise<LoginStore | ManagerStore>
	{
		const business = await this.waiterService.enterBusiness();
        return business ? this.enterManager(business, view) : this.enterLogin();
	}

	async enterManager(business: Business, view?: ManagerView): Promise<ManagerStore>
	{
        const managerBusinessProvider = {
            business: business,
        };
        const managerBrandingService = new BrandingService(managerBusinessProvider);
        const managerStore = new ManagerStore(
            this,
            this.bridge,
            business,
            this.waiterService,
            managerBrandingService,
            view,
        );

        await this.push(
            Screens.Manager,
            managerStore,
        );

        return managerStore;
    }

    parseImage(imageElement: HTMLImageElement): void
    {
        this.startScanning()
            .then(
                () =>
                {
                    const parser = new QrCodeParser(
                        scan =>
                        {
                            if (scan.code)
                            {
                                const hash = getPlaceCodeHashFromQrCode(scan.code);

                                if (hash)
                                {
                                    this.enterBusinessFromHash(hash, false)
                                        .catch(
                                            () =>
                                                this.scannerStore.placeCodeScannerStore.setScannerState(PlaceCodeScannerState.invalidQrCode));
                                }
                                else
                                {

                                    this.scannerStore.placeCodeScannerStore.setScannerState(PlaceCodeScannerState.unknownQrCode);
                                }
                            }
                            else
                            {
                                this.scannerStore.placeCodeScannerStore.setScannerState(PlaceCodeScannerState.noQrCode);
                            }
                        },
                        () => this.scannerStore.placeCodeScannerStore.setScannerState(PlaceCodeScannerState.error),
                        () => this.scannerStore.placeCodeScannerStore.setScannerState(PlaceCodeScannerState.noQrCode));

                    parser.parseImage(imageElement);
                });
    }

    initializeHashOfLockedPlace(): void
    {
        const hashOfLockedPlace = this.bridge.storage.get(StorageVars.HashOfLockedPlace);
        this.setHashOfLockedPlace(hashOfLockedPlace);
    }

    changeHashOfLockedPlace(hash: string | undefined): void
    {
        this.setHashOfLockedPlace(hash);
        this.bridge.storage.set(StorageVars.HashOfLockedPlace, hash);
    }

    setHashOfLockedPlace(hash: string | undefined)
    {
        this.hashOfLockedPlace = hash;
    }

    pop(force?: boolean, uuidOfScreenInstantiationToPop?: string): Promise<boolean>
    {
        return this.bridge.navigator.popScreen(force, uuidOfScreenInstantiationToPop);
    }

    push(screenId: string, store: BaseStore): Promise<ScreenInstantiation>
    {
        return this.bridge.navigator.pushScreen(
            screenId,
            store
        );
    }

    public async navigateToLockedPlaceIfPresent(): Promise<void>
    {
        if (this.hashOfLockedPlace !== undefined)
            await this.enterBusinessFromHash(this.hashOfLockedPlace, true);
    }

    async openHistory(force: boolean = false): Promise<HistoryStore>
    {
        if (force || (!this.hideOrderHistory && this.orderService.orders.length > 0))
        {
            this.isMenuOpen.set(false);

            await this.push(
                Screens.History,
                this.historyStore,
            );
        }

        return this.historyStore;
    }

    openProfile(): Promise<ProfileStore>
    {
        this.isMenuOpen.set(false);

        return this.push(
            Screens.Profile,
            this.profileStore)
            .then(() => Promise.resolve(this.profileStore));
    }

    openManagerPanel()
    {
        this.goBackToEntrance()
            .then(() => this.enterLogin());
    }

    // ------------------------ Public logic ------------------------

    goBackToEntrance()
    {
        if (!(this.bridge.navigator.currentScreenInstance?.store instanceof EntranceStore))
        {
            return this.pop()
                .then(() => this.goBackToEntrance());
        }
        else
        {
            return Promise.resolve();
        }
    }

    async getEffectiveHash(hash: string): Promise<string | undefined>
    {
        if (this.hashOfLockedPlace !== undefined)
        {
            return this.hashOfLockedPlace;
        }
		else if (hash === NoHashValue)
		{
			await this.scaninService.onInitialized;
			this.scaninService.currentScanin.syncWithStorage();

            return this.scaninService.currentScanin.value?.placeHash;
        }
        else
        {
            return hash;
        }
    }

    async createBusinessStoreFromHash(hash: string): Promise<BusinessStore | undefined>
    {
        const effectiveHash = await this.getEffectiveHash(hash);

        if (effectiveHash !== undefined)
        {
            const hashData = await this.getHashData(effectiveHash);

            if (hash === NoHashValue)
            {
                const didScanExpire = await this.didScanExpire(hashData);

                if (didScanExpire)
                {
                    this.scaninService.currentScanin.set(undefined);
                    return undefined;
                }
                else
                {
                    this.scaninService.currentScanin.syncWithStorage();
                    if (this.scaninService.currentScanin.value?.placeHash !== effectiveHash)
                        return this.createBusinessStoreFromHash(hash);
                }
            }

            return this.createBusinessStore(
                hashData.business,
                hashData.place,
                hashData.hash,
            );
        }
    }

    createBusinessStore(
        business: Business,
        place: Place,
        hash: string,
        openedCategoryIds?: number[],
    ): BusinessStore
    {
        return new BusinessStore(
            this.bridge,
            this,
            this.currentPlaceService,
            this.scaninService,
            this.profileStore,
            business,
            place,
            hash,
            Promise.resolve,
            this.isMenuOpen,
            this.pop,
            this.push,
            openedCategoryIds,
            this.orderService,
            this.authenticationResult,
            this.storage
        );
    }

    public async openPlace(place: Place, locked: boolean, fromRoot: boolean): Promise<void>
    {
        const placeCode = place.placeCodes?.[0];

        if (placeCode === undefined)
            return Promise.reject(`Place ${place.id} has no valid place code`);

        if (fromRoot)
            await this.goBackToEntrance();

       await this.enterBusinessFromHash(placeCode.hash, locked);
    }

    // ----------------------- Private logic ------------------------

    private getHashData(hash: string): Promise<HashData>
    {
        return fetch<HashData>(
            '/client/business/hash/data',
            {
                hash,
            },
            HashDataProfile,
        );
    }

	private async didScanExpire(hashData: HashData): Promise<boolean>
	{
		await this.scaninService.onInitialized;

		if (this.hashOfLockedPlace === hashData.hash)
		{
			return false;
		}
		else if (this.scaninService.currentScanin.value?.placeHash === undefined)
		{
			return false;
		}
		else
		{
			const endOfLastInPlace = this.currentPlaceService.endOfLastInPlace;

			if (endOfLastInPlace === undefined)
			{
				return true;
			}
			else if (hashData.place.scaninExpiryDuration === undefined)
			{
				return false;
			}
			else
			{
				return isAfter(
					addSeconds(
						new Date(),
						-toSeconds(hashData.place.scaninExpiryDuration),
					),
					endOfLastInPlace,
				);
			}
		}
	}
}
