import * as React from 'react';
import { FC } from 'react';

interface RequestBillIconProps
{
	color: string;
}

export const RequestBillIcon: FC<RequestBillIconProps> =
	({
		color,
	}) =>
	{
		return <svg
			width="45"
			height="45"
			viewBox="0 0 113 113"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M95.3791 8.82812H17.6209C12.7725 8.82812 8.82812 12.7725 8.82812 17.6209V102.406C8.82812 103.06 9.18831 103.66 9.76744 103.964C10.343 104.267 11.0422 104.235 11.5825 103.868L20.3047 97.9745L29.0127 103.868C29.6095 104.271 30.3935 104.271 30.9938 103.868L39.7124 97.9745L48.4452 103.872C49.042 104.274 49.8224 104.274 50.4227 103.872L59.1414 97.9781L67.8812 103.875C68.478 104.278 69.2584 104.278 69.8552 103.875L78.6127 97.9781L87.3702 103.875C87.914 104.243 88.6097 104.278 89.1853 103.971C89.7608 103.663 90.121 103.067 90.121 102.413V46.7643L92.6671 45.0517L101.425 50.9489C101.965 51.3161 102.664 51.3479 103.24 51.0442C103.815 50.737 104.175 50.1402 104.175 49.4869V17.6209C104.172 12.7725 100.227 8.82812 95.3791 8.82812ZM86.5863 17.6209V99.0869L79.5944 94.3797C78.9976 93.9772 78.2172 93.9772 77.6204 94.3797L68.8629 100.277L60.1195 94.3797C59.5227 93.9772 58.7388 93.9772 58.142 94.3797L49.4269 100.273L40.6941 94.3762C40.0973 93.9736 39.3169 93.9736 38.7166 94.3762L29.998 100.27L21.2934 94.3797C20.6967 93.9772 19.9127 93.9772 19.3159 94.3797L12.3594 99.0833V17.6209C12.3594 14.7182 14.7182 12.3594 17.6209 12.3594H88.3378C87.2395 13.8284 86.5863 15.6505 86.5863 17.6209ZM100.641 46.1676L93.6488 41.4604C93.0555 41.0578 92.2716 41.0578 91.6748 41.4604L90.1175 42.5092V17.6209C90.1175 14.7182 92.4764 12.3594 95.3791 12.3594C98.2818 12.3594 100.641 14.7182 100.641 17.6209V46.1676Z"
				fill={color}
			/>
			<path
				d="M23.1403 31.4316H75.8053C76.7799 31.4316 77.5709 30.6406 77.5709 29.666C77.5709 28.6914 76.7799 27.9004 75.8053 27.9004H23.1403C22.1656 27.9004 21.3746 28.6914 21.3746 29.666C21.3746 30.6406 22.1656 31.4316 23.1403 31.4316Z"
				fill={color}
			/>
			<path
				d="M23.1403 46.842H75.8053C76.7799 46.842 77.5709 46.051 77.5709 45.0764C77.5709 44.1018 76.7799 43.3108 75.8053 43.3108H23.1403C22.1656 43.3108 21.3746 44.1018 21.3746 45.0764C21.3746 46.051 22.1656 46.842 23.1403 46.842Z"
				fill={color}
			/>
			<path
				d="M23.1403 62.2489H75.8053C76.7799 62.2489 77.5709 61.4579 77.5709 60.4832C77.5709 59.5086 76.7799 58.7176 75.8053 58.7176H23.1403C22.1656 58.7176 21.3746 59.5086 21.3746 60.4832C21.3746 61.4579 22.1656 62.2489 23.1403 62.2489Z"
				fill={color}
			/>
			<path
				d="M23.1403 77.6557H75.8053C76.7799 77.6557 77.5709 76.8647 77.5709 75.8901C77.5709 74.9155 76.7799 74.1245 75.8053 74.1245H23.1403C22.1656 74.1245 21.3746 74.9155 21.3746 75.8901C21.3746 76.8647 22.1656 77.6557 23.1403 77.6557Z"
				fill={color}
			/>
		</svg>;
	};
