import { Duration } from 'iso8601-duration';
import { makeAutoObservable } from 'mobx';
import { AdyenCheckoutEnvironment } from '../../Component/UI/payment/adyen/checkout/AdyenCheckoutEnvironment';
import { SerializationProfile, StringToDurationTransformer } from '../../Util/Serialization/Serialization';
import { VatGroup } from '../vat_group/VatGroup';
import { Address, AddressProfile } from './Address';
import { BrandingInformation, BrandingInformationProfile } from './BrandingInformation';
import { OrderRestriction, OrderRestrictionProfile } from './OrderRestriction';
import { Routing, RoutingProfile } from './Routing/Routing';

export class Business
{
    id: number;
    uuid: string;
    name: string;
    informationTitle?: string;
    information?: string;
    informationUrl?: string;
    logoUrl: string;
    address: Address;
    brandingInformation: BrandingInformation;
    productCurrencyCode: string;
    butlarooPayEnvironment?: AdyenCheckoutEnvironment;
    payPalClientId?: string;
    isServicePaused: boolean;
    servicePauseDate: number;
    orderRestrictions: OrderRestriction[];
    public readonly orderTrackerBeneficiary?: string;
    public readonly embeddableHtmlAfterOrderTimeout: Duration;
    public readonly timeZone: string;
    orderHandlerScopingEnabled: boolean;
    isSharePaymentWithTikkieSupported: boolean;
    public readonly serviceFeeVatGroup?: VatGroup;
    public readonly serviceFeeRouting?: Routing;
    public readonly tipVatGroup?: VatGroup;
    public readonly tipRouting?: Routing;

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }
}

export const BusinessProfile =
    SerializationProfile.create(Business)
        .profile('address', AddressProfile)
        .rewrite('paymentFeeVatGroup', 'serviceFeeVatGroup')
        .rewrite('paymentFeeRouting', 'serviceFeeRouting')
        .profile('serviceFeeRouting', RoutingProfile)
        .profile('tipRouting', RoutingProfile)
        .profile('brandingInformation', BrandingInformationProfile)
        .profile('orderRestrictions', OrderRestrictionProfile)
        .transform('embeddableHtmlAfterOrderTimeout', StringToDurationTransformer);
