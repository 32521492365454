import { useCallback, useContext } from 'react';
import { AuthenticationContext } from '../../../Component/authentication-provider/AuthenticationContext';
import { Fetch } from './Fetch';
import { FetchHeadersContext } from './FetchHeadersContext';
import { getInitializedRequestInfo } from './getInitializedRequestInfo';

export function useFetch(): Fetch
{
	const headers = useContext(FetchHeadersContext);
	const {headers: authenticationHeaders} = useContext(AuthenticationContext);

	return useCallback(
		(input, init) =>
		{
			return fetch(
				getInitializedRequestInfo(input, init?.params),
				{
					...init,
					headers: {
						...headers,
						...authenticationHeaders.current,
						...init?.headers,
					},
				},
			);
		},
		[
			authenticationHeaders,
			headers,
		],
	);
}
