import { CSSProperties } from 'react';
import { TextElement } from '../../../../../../../Api/v3/model/story_post/content/element/TextElement';
import { getLinearGradientCss } from '../../../api/getLinearGradientCss';
import { getSolidColorCss } from '../../../api/getSolidColorCss';

const BorderRadiusFactor = 0.5;

export function useTextBackgroundElementStyleProps(
	element: Pick<TextElement, 'backgroundColor' | 'textSize'>,
	postHeight: number,
): CSSProperties
{
	return {
		borderRadius: `${element.textSize * BorderRadiusFactor * postHeight}px`,
		...element.backgroundColor?.type === 'Solid'
			? {
				backgroundColor: getSolidColorCss(element.backgroundColor),
			}
			: {},
		...element.backgroundColor?.type === 'LinearGradient'
			? {
				transformOrigin: '50% 50%',
				background: getLinearGradientCss(element.backgroundColor),
			}
			: {},
	};
}
