import { FC, PropsWithChildren, useRef } from 'react';
import { useElementDimensions } from '../../../../Util/element/useElementDimensions';
import { StoryPostHeightProvider } from '../post_viewer/context/StoryPostHeightProvider';
import { StoryPostWidthProvider } from '../post_viewer/context/StoryPostWidthProvider';

const DefaultWidth = 200;

interface StoryPostContainerProps
{
	width?: number;
	fullScreen?: boolean;
}

export const StoriesPlayerContainer: FC<PropsWithChildren<StoryPostContainerProps>> =
	({
		fullScreen,
		width = DefaultWidth,
		children,
	}) =>
	{
		const rootRef = useRef<HTMLDivElement>(null);

		const {width: measuredWidth, height} = useElementDimensions(rootRef);

		return <div
			ref={rootRef}
			style={{
				width: fullScreen
					? '100%'
					: width,
				height: fullScreen
					? '100%'
					: undefined,
				aspectRatio: fullScreen
					? undefined
					: '9 / 19.5',
				backgroundColor: fullScreen
					? 'black'
					: undefined,
			}}
		>
			<StoryPostWidthProvider.Provider
				value={measuredWidth ?? width}
			>
				<StoryPostHeightProvider.Provider
					value={height}
				>
					{children}
				</StoryPostHeightProvider.Provider>
			</StoryPostWidthProvider.Provider>
		</div>;
	};
