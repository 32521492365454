import { FC } from 'react';
import { SolidColorFill } from '../../../../../../Api/v3/model/story_post/content/shared/color_fill/SolidColorFill';
import { getSolidColorCss } from '../../api/getSolidColorCss';

interface SolidColorBackgroundViewerProps
{
	backgroundColor: SolidColorFill;
}

export const SolidColorBackgroundViewer: FC<SolidColorBackgroundViewerProps> =
	({
		backgroundColor,
	}) =>
	{
		return <div
			style={{
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,

				backgroundColor: getSolidColorCss(backgroundColor),
			}}
		/>;
	};
