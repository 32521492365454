import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, makeObservable, observable } from 'mobx';
import { Business } from '../../../../Api/Business/Business';
import { Place } from '../../../../Api/Business/Place';
import { Color } from '../../../../Api/Other/Color';
import { NutritionFlag } from '../../../../Api/Product/NutritionFlag';
import { Product } from '../../../../Api/Product/Product';
import { ProductCategory } from '../../../../Api/Product/ProductCategory';
import { Bridge } from '../../../../Bridge/Bridge';
import { Screens } from '../../../../Constants/ScreenConstants';
import { BrandingService } from '../../../../Service/BrandingInformation/BrandingService';
import { IllegalStateException } from '../../../../Util/Exception/IllegalStateException';
import { EntranceStore } from '../../Entrance/EntranceStore';
import { BusinessStore } from '../BusinessStore';
import { OrderBuilderStore } from '../OrderBuilder/OrderBuilderStore';
import { ProductRecommendationStore } from '../Product/ProductRecommendation/ProductRecommendationStore';
import { ProductStore } from '../Product/ProductStore';
import { ShoppingCartStore } from '../ShoppingCart/ShoppingCartStore';
import { getStoriesFilteredByLocation } from '../StoriesPlayer/Api/getStoriesFilteredByLocation';
import { StoryWithPosts } from '../StoriesPlayer/Model/StoryWithPosts';
import { shouldShowOnMenu } from './Api/shouldShowOnMenu';

const ROOT_PRODUCT_CATEGORY_ID = 1;

export class MenuStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    public readonly bridge: Bridge;
    public readonly entranceStore: EntranceStore;
    public readonly businessStore: BusinessStore;
    public readonly business: Business;
    public readonly place: Place;
    public readonly brandingService: BrandingService;
    public readonly createChildMenuStore: (category: ProductCategory, parentStore?: MenuStore) => MenuStore;
    public readonly category: ProductCategory;
    public readonly parentCategory: ProductCategory;
    private readonly showStories: boolean;
    public readonly parentMenuStore: MenuStore | undefined;
    public readonly shoppingCartStore: ShoppingCartStore;
    public readonly depth: number;
    public readonly openedCategoryIds?: number[];
    public readonly productCategoryById?: Map<number, ProductCategory>;
    public readonly productById?: Map<number, Product>;
    public readonly hasAllergen: (allergen: string) => boolean;
    public readonly needsToSeeNutritionFlag: (nutritionFlag: NutritionFlag) => boolean;
    public readonly openHistory: (force?: boolean) => Promise<any>;
    public readonly openOrderBuilder: () => Promise<OrderBuilderStore>;
    public readonly openProduct: (product: Product, doPushScreen?: boolean) => Promise<ProductStore>;
    public readonly openProductRecommendations: (triggeringProductId: number) => Promise<ProductRecommendationStore>;
    public readonly orderBill: () => void;
    public readonly orderWaiter: () => void;
    public readonly childMenuStores = observable.array<MenuStore>();

    /**
     * @deprecated Deprecated: this is now being tracked in WebClient (should be added to NativeClient too)
     */
    public scrollYOffset: number;
    public openSubMenu: MenuStore | undefined;

    // ------------------------ Constructor -------------------------

    constructor(
        bridge: Bridge,
        entranceStore: EntranceStore,
        businessStore: BusinessStore,
        business: Business,
        place: Place,
        brandingService: BrandingService,
        createChildMenuStore: (category: ProductCategory, parentStore?: MenuStore) => MenuStore,
        shoppingCartStore: ShoppingCartStore,
        category: ProductCategory,
        parentCategory: ProductCategory,
        showStories: boolean,
        hasAllergen: (allergen: string) => boolean,
        needsToSeeNutritionFlag: (nutritionFlag: NutritionFlag) => boolean,
        openHistory: (force?: boolean) => Promise<any>,
        openOrderBuilder: () => Promise<OrderBuilderStore>,
        openProduct: (product: Product, doPushScreen?: boolean) => Promise<ProductStore>,
        openProductRecommendations: (triggeringProductId: number) => Promise<ProductRecommendationStore>,
        orderBill: () => void,
        orderWaiter: () => void,
        parentMenuStore?: MenuStore,
        depth: number = 0,
        openedCategoryIds?: number[],
        productCategoryById?: Map<number, ProductCategory>,
        productById?: Map<number, Product>,
    )
    {
        super();

        makeObservable(
            this,
            {
                scrollYOffset: observable,
                openSubMenu: observable,
                announcementsForCategory: computed,
                furthestOpenedMenuStore: computed,
                stories: computed,
                childMenuStoreByCategory: computed,
                openMenu: action.bound,
                openChildCategory: action.bound,
                closeMenu: action.bound,
                close: action.bound,
                setScrollYOffset: action.bound,
            },
        );

        this.bridge = bridge;
        this.entranceStore = entranceStore;
        this.businessStore = businessStore
        this.business = business;
        this.place = place;
        this.brandingService = brandingService;
        this.createChildMenuStore = createChildMenuStore;
        this.shoppingCartStore = shoppingCartStore;
        this.category = category;
        this.parentCategory = parentCategory;
        this.showStories = showStories;
        this.hasAllergen = hasAllergen;
        this.needsToSeeNutritionFlag = needsToSeeNutritionFlag;
        this.openHistory = openHistory;
        this.openOrderBuilder = openOrderBuilder;
        this.openProduct = openProduct;
        this.openProductRecommendations = openProductRecommendations;
        this.orderBill = orderBill;
        this.orderWaiter = orderWaiter;
        this.parentMenuStore = parentMenuStore;
        this.depth = depth;
        this.openedCategoryIds = openedCategoryIds;
        this.productCategoryById = productCategoryById;
        this.productById = productById;

        this.category.categories
            .map(
                category =>
                    this.childMenuStores.push(this.createChildMenuStore(category, this)));

        if (depth < this.openedCategoryIds.length)
        {
            this.openChildCategory(
                this.productCategoryById.get(
                    this.openedCategoryIds[depth])!);
        }
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------


    get announcementsForCategory()
    {
        return this
            .businessStore
            .announcementsForCurrentPlace
            .filter(announcement => {
                const isNotForSpecificProduct = !announcement.isAssignedToProduct;
                const isForProductCategory = announcement.isAssignedToProductCategory && announcement.productCategoryIds.includes(this.category.id);
                const isDisplayedInMenu = announcement.isDisplayedInMenu;

                if (this.category.id === ROOT_PRODUCT_CATEGORY_ID)
                {
                    const isForAllProductCategories = !announcement.isAssignedToProductCategory;

                    return isDisplayedInMenu && isNotForSpecificProduct && (isForAllProductCategories || isForProductCategory);
                }

                return isDisplayedInMenu && isNotForSpecificProduct && isForProductCategory;
            });
    }

    firstProductWithImage(now: Date, ignoreTimeSchedules: boolean): Product | undefined
    {
        const firstProductWithImage = ignoreTimeSchedules
            ? this
                .category
                .products
                .find(({imageUrl}) => imageUrl != null)
            : this
                .category
                .products
                .filter(product => shouldShowOnMenu(product, now))
                .find(({imageUrl}) => imageUrl != null);

        if (firstProductWithImage)
        {
            return firstProductWithImage;
        }
        else
        {
            return this.childMenuStores
                .map(menuStore => menuStore.firstProductWithImage(now, ignoreTimeSchedules))
                .find(product => product != null);
        }
    }

    getFirstProductImageUrl(now: Date, ignoreTimeSchedules: boolean): string | undefined
    {
        let firstProductWithImage = this.firstProductWithImage(now, ignoreTimeSchedules);
        return firstProductWithImage != null
            ?
            firstProductWithImage.imageUrl
            :
            undefined;
    }

    getImageUrl(now: Date, ignoreTimeSchedules: boolean): string | undefined
    {
        if (this.category.imageUrl)
        {
            return this.category.imageUrl;
        }
        else if (this.getFirstProductImageUrl(now, ignoreTimeSchedules))
        {
            return this.getFirstProductImageUrl(now, ignoreTimeSchedules);
        }
        else
            return undefined;
    }

    getImageBackgroundColor(now: Date, ignoreTimeSchedules: boolean): Color | undefined
    {
        if (this.category.imageUrl)
        {
            return this.category.imageBackgroundColor;
        }
        else if (this.getFirstProductImageUrl(now, ignoreTimeSchedules))
        {
            return this.firstProductWithImage(now, ignoreTimeSchedules)?.imageBackgroundColor;
        }
        else
        {
            return undefined;
        }
    }

    getImageHasTextBackdrop(now: Date, ignoreTimeSchedules: boolean): boolean | undefined
    {
        if (this.category.imageUrl)
        {
            return this.category.imageHasTextBackdrop;
        }
        else if (this.getFirstProductImageUrl(now, ignoreTimeSchedules))
        {
            return this.firstProductWithImage(now, ignoreTimeSchedules)?.imageHasTextBackdrop;
        }
        else
        { // using logo
            return undefined;
        }
    }

    getImageIsTextContrastColorDark(now: Date, ignoreTimeSchedules: boolean): boolean | undefined
    {
        if (this.category.imageUrl)
        {
            return this.category.imageIsTextContrastColorDark;
        }
        else if (this.getFirstProductImageUrl(now, ignoreTimeSchedules))
        {
            return this.firstProductWithImage(now, ignoreTimeSchedules)?.imageIsTextContrastColorDark;
        }
        else
        { // using logo
            return undefined;
        }
    }

    getImageDoContain(now: Date, ignoreTimeSchedules: boolean): boolean | undefined
    {
        if (this.category.imageUrl)
        {
            return this.category.imageDoContain;
        }
        else if (this.getFirstProductImageUrl(now, ignoreTimeSchedules))
        {
            return this.firstProductWithImage(now, ignoreTimeSchedules)?.imageDoContain;
        }
        else
        { // using logo
            return true;
        }
    }

    get furthestOpenedMenuStore(): MenuStore
    {
        if (this.openSubMenu)
        {
            return this.openSubMenu.furthestOpenedMenuStore;
        }
        else
        {
            return this;
        }
    }

    get hasCarouselAncestor(): boolean
    {
        return this.category.menuEntryType === 'CAROUSEL'
            || (this.parentMenuStore !== undefined && this.parentMenuStore.hasCarouselAncestor);
    }

    get stories(): StoryWithPosts[]
    {
        if (this.showStories)
        {
            return getStoriesFilteredByLocation(
                this.category.id === ROOT_PRODUCT_CATEGORY_ID
                    ?
                    {
                        type: 'Menu',
                    }
                    : {
                        type: 'ProductCategory',
                        productCategoryId: this.category.uuid,
                    },
                this.businessStore.stories,
            );
        }
        else
        {
            return [];
        }
    }

    // --------------------------- Stores ---------------------------

    get childMenuStoreByCategory(): Map<ProductCategory, MenuStore>
    {
        const map = new Map<ProductCategory, MenuStore>();

        this.childMenuStores.forEach(
            menuStore =>
                map.set(
                    menuStore.category,
                    menuStore));

        return map;
    }

    // -------------------------- Actions ---------------------------

    openMenu(menuStore: MenuStore): Promise<any>
    {
        return this.bridge.navigator.pushScreen(
            Screens.Menu,
            menuStore
        );
    }

    openChildCategory(category: ProductCategory): Promise<MenuStore>
    {
        const menuStore = this.childMenuStoreByCategory.get(category);

        if (menuStore)
        {
            return this.openMenu(menuStore)
                .then(
                    () =>
                        Promise.resolve(menuStore));
        }
        else
        {
            return Promise.reject('Menu not found');
        }

        // this.openSubMenu =
        //     this.childMenuStoreByCategory.get(category);
    }

    closeMenu()
    {
        this.openSubMenu = undefined;
    }

    close(): Promise<any>
    {
        return this.bridge.navigator.popScreen();
    }

    /**
     * @deprecated Deprecated, see {@link MenuStore#scrollYOffset}
     */
    setScrollYOffset(offset: number)
    {
        this.scrollYOffset = offset;
    }

    // ------------------------ Public logic ------------------------

    getProducts(nrOfTilesPerGridLine: number): Product[]
    {
        // if (nrOfTilesPerGridLine !== 2) {
        //     return this.category.products;
        // }
        const sorted: Product[] = [];
        const toSort = [];
        this.category.products.forEach(p => toSort.push(p));
        let leftOverForNextChunk = [];
        while (toSort.length > 0) {
            let chunk: Product[] = [];
            leftOverForNextChunk.forEach(p => chunk.push(p));
            leftOverForNextChunk = [];
            while (toSort.length > 0) {
                let next = toSort[0];
                toSort.splice(0, 1);
                chunk.push(next);
                if (next.menuEntryType === 'LIST_ITEM') {
                    break;
                }
            }
            if (chunk.length > 0) {
                let last = chunk[chunk.length - 1];
                let tilesOnLastRowOfChunk = chunk.length % nrOfTilesPerGridLine > 0 ? chunk.length % nrOfTilesPerGridLine : nrOfTilesPerGridLine;
                if (last.menuEntryType === 'LIST_ITEM' && tilesOnLastRowOfChunk !== 1) {
                    let firstTileOnRowOfChunk = chunk[chunk.length - tilesOnLastRowOfChunk];
                    chunk[chunk.length - tilesOnLastRowOfChunk] = last;
                    chunk[chunk.length - 1] = firstTileOnRowOfChunk;
                    for (let j = chunk.length - tilesOnLastRowOfChunk + 1; j < chunk.length; j++) {
                        leftOverForNextChunk.push(chunk[j]);
                    }
                    chunk = chunk.slice(0, chunk.length - tilesOnLastRowOfChunk + 1);
                }
            }
            for (let p of chunk) {
                sorted.push(p);
            }
        }
        return sorted;
    }

    public getNrOfListItemRows(
        products: Product[]
    )
    {
        return products
            .map(p => p.menuEntryType === 'LIST_ITEM')
            .length;
    }

    public getNrOfRows(
        nrOfTilesPerGridLine: number,
        nrOfCategories: number,
        products: Product[]
    )
    {
        let nrOfRows = 0;
        nrOfRows += nrOfCategories / nrOfTilesPerGridLine;
        nrOfRows += ((nrOfCategories % nrOfTilesPerGridLine) > 0)
            ?
            1
            :
            0;

        const nrOfTileProducts = products
            .map(p => p.menuEntryType === 'TILE')
            .length;
        nrOfRows += nrOfTileProducts / nrOfTilesPerGridLine;
        nrOfRows += ((nrOfTileProducts % nrOfTilesPerGridLine) > 0)
            ?
            1
            :
            0;
        const nrOfListItemProducts = this.getNrOfListItemRows(products);
        nrOfRows += nrOfListItemProducts;
        return nrOfRows;
    }

    public getNrOfTileRows(
        totalNrOfRows: number,
        nrOfListItemRows: number
    )
    {
        return totalNrOfRows - nrOfListItemRows;
    }

    public getTotalTileWidth(nrOfTilesPerGridLine: number, upToProductIdx?: number): number
    {
        if (upToProductIdx === undefined) {
            upToProductIdx = this.category.products.length - 1;
        }
        if (upToProductIdx >= this.category.products.length) {
            throw new IllegalStateException();
        }
        let quantity = 0;
        for (let i = 0; i <= upToProductIdx; i++) {
            let product = this.category.products[i];
            if (product.menuEntryType === 'LIST_ITEM') {
                quantity += nrOfTilesPerGridLine;
            } else {
                quantity += 1;
            }
        }
        return quantity;
    }

    getOpenedCategories(): ProductCategory[]
    {
        if (this.openSubMenu)
        {
            return [this.openSubMenu.category]
                .concat(
                    this.openSubMenu.getOpenedCategories());
        }
        else
        {
            return [];
        }
    }

    // ----------------------- Private logic ------------------------
}
