import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { HateoasStoryPost } from '../../../../../Api/v3/model/story_post/HateoasStoryPost';
import { StoryPlayerPostProgress } from './post/StoryPlayerPostProgress';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		gap: theme.spacing(0.5),
		width: '100%',
	},
}));

interface StoryPlayerProgressProps
{
	posts: HateoasStoryPost[];
	postIdx: number;
	active: boolean;
	onContinue: () => void;
}

export const StoryPlayerProgress: FC<StoryPlayerProgressProps> =
	({
		posts,
		postIdx,
		active,
		onContinue,
	}) =>
	{
		const classes = useStyles();

		return <div
			className={classes.root}
		>
			{
				posts.map(
					(post, idx) =>
						<StoryPlayerPostProgress
							key={post.id}
							post={post}
							active={active && idx === postIdx}
							completed={postIdx > idx}
							onContinue={onContinue}
						/>,
				)
			}
		</div>;
	};
