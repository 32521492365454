import { FC } from 'react';
import { LanguageEntry } from '../../../Api/v3/model/language_entry/LanguageEntry';
import { useLocalizedLanguageEntry } from './useLocalizedLanguageEntry';

interface LocalizedLanguageEntryProps
{
	entry?: LanguageEntry;
}

export const LocalizedLanguageEntry: FC<LocalizedLanguageEntryProps> =
	({
		entry,
	}) =>
	{
		const localizedLanguageEntry = useLocalizedLanguageEntry(entry);

		return <>
			{localizedLanguageEntry}
		</>;
	};
