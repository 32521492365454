import { RefObject, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useElementDimensions(ref: RefObject<HTMLElement>): DOMRectReadOnly
{
	const [contentRect, setContentRect] = useState(() => new DOMRect());

	useEffect(
		() =>
		{
			const resizeObserver = new ResizeObserver(
				(entries: ResizeObserverEntry[]) =>
					setContentRect(entries[0].contentRect),
			);

			resizeObserver.observe(ref.current);

			return () =>
			{
				resizeObserver.disconnect();
			};
		},
		[ref],
	);

	return contentRect;
}
