import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useElementDimensions } from '../../Util/element/useElementDimensions';
import { CubeFace } from './CubeFace';

const RotationPercentageCssPropertyName = '--rotationPercentage';

// Percentage of 90°
const RotationThreshold = 0.25;

interface ElementProps
{
	idx: number;
	onPrevious: () => void;
	onNext: () => void;
}

interface CubeTransitionProps
{
	idx: number;
	onChangeIdx: (idx: number | undefined) => void;
	renderElement: (props: ElementProps) => ReactNode;
	numberOfElements: number;
	onChangeIsInteractedWith: (value: boolean) => void;
	disabled?: boolean;
}

const TransitionDuration = 250;

// Inspired by https://codepen.io/stevenlei/pen/qBjaNBQ
export const CubeTransition: FC<CubeTransitionProps> =
	({
		idx,
		onChangeIdx: setIdx,
		renderElement,
		numberOfElements,
		onChangeIsInteractedWith,
		disabled,
	}) =>
	{
		const [isMouseDown, setMouseDown] = useState(false);
		const [rotationPercentage, setRotationPercentage] = useState(0);

		const index = useRef(idx);
		const isTransitioning = useRef(false);
		const sceneRef = useRef<HTMLDivElement>(null);
		const cubeRef = useRef<HTMLDivElement>(null);

		const {width} = useElementDimensions(sceneRef);

		const isRotating = useMemo(
			() =>
				rotationPercentage !== 0,
			[rotationPercentage],
		);

		const handleTransition = useCallback(
			(percentageString: string) =>
			{
				isTransitioning.current = true;
				sceneRef.current?.style.setProperty(RotationPercentageCssPropertyName, percentageString);
				cubeRef.current?.style.setProperty('transition', `transform ${TransitionDuration}ms`);
			},
			[],
		);

		useEffect(
			() =>
			{
				let touchX = 0;
				let percentage = 0;
				setRotationPercentage(0);
				isTransitioning.current = false;
				const scene = sceneRef.current;
				scene.style.setProperty(RotationPercentageCssPropertyName, '0');
				const cube = cubeRef.current;
				index.current = idx;
				let isMouseCurrentlyDown = false;
				setMouseDown(isMouseCurrentlyDown);

				const touchStartHandler =
					(event: TouchEvent) =>
					{
						onChangeIsInteractedWith(true);

						if (!isTransitioning.current)
						{
							let target;

							if (typeof event.touches !== 'undefined' && event.touches.length === 1)
							{
								target = event.touches[0];
							}
							else
							{
								target = event;
								isMouseCurrentlyDown = true;
								setMouseDown(isMouseCurrentlyDown);
							}

							touchX = target.clientX;
						}
					};

				if (!disabled)
				{
					window.addEventListener('touchstart', touchStartHandler, {passive: false});
					window.addEventListener('mousedown', touchStartHandler, {passive: false});
				}

				function isSwipeTo(
					direction: 'left' | 'right',
					percentage: number,
				)
				{
					if (direction === 'left')
					{
						return percentage > 0;
					}

					if (direction === 'right')
					{
						return percentage < 0;
					}
				}

				const touchMoveHandler =
					(event: TouchEvent) =>
					{
						// Hijack window event
						// 	1. Swiping browser navigation should be disabled
						// 	2. This element has precedence over other potential elements, since it is intended to be the center of attention
						event.preventDefault();

						if (typeof event.touches !== 'undefined' && event.touches.length !== 1)
							return;

						if (!isTransitioning.current)
						{
							let target;

							if (typeof event.touches !== 'undefined' && event.touches.length === 1)
							{
								target = event.touches[0];
							}
							else
							{
								if (!isMouseCurrentlyDown)
								{
									return;
								}

								target = event;
							}

							let offset = target.clientX - touchX;
							percentage = offset / width;

							// Keep the transition works
							if (percentage > 0.95)
								percentage = 0.95;

							if (percentage < -0.95)
								percentage = -0.95;

							// Prevent move to left for first image
							if (index.current === 0 && isSwipeTo('left', percentage))
							{
								percentage = 0;
							}

							// Prevent move to right for last image
							if (index.current === numberOfElements - 1 && isSwipeTo('right', percentage))
							{
								percentage = 0;
							}

							setRotationPercentage(percentage);
							scene.style.setProperty(RotationPercentageCssPropertyName, `${percentage}`);
						}
					};

				if (!disabled)
				{
					window.addEventListener('touchmove', touchMoveHandler, {passive: false});
					window.addEventListener('mousemove', touchMoveHandler, {passive: false});
				}

				const touchEndHandler =
					(event: TouchEvent) =>
					{
						if (typeof event.touches !== 'undefined' && event.touches.length !== 0)
							return;

						onChangeIsInteractedWith(false);

						if (!isTransitioning.current)
						{
							if (Math.abs(percentage) > RotationThreshold && Math.abs(percentage) <= 1)
							{
								percentage = percentage > 0
									? 1
									: -1;
							}
							else
							{
								percentage = 0;
							}

							setRotationPercentage(percentage);
							turn(percentage);
							isMouseCurrentlyDown = false;
							setMouseDown(isMouseCurrentlyDown);
						}
					};

				if (!disabled)
				{
					window.addEventListener('touchend', touchEndHandler, {passive: false});
					window.addEventListener('mouseup', touchEndHandler, {passive: false});
				}

				const turn =
					(direction: number) =>
					{
						if (direction === 1)
						{
							// Left
							index.current = Math.max(
								0,
								index.current - 1,
							);
						}
						else if (direction === -1)
						{
							// Right
							index.current = Math.min(
								index.current + 1,
								numberOfElements - 1,
							);
						}

						const currentPercentage = scene.style.getPropertyValue(RotationPercentageCssPropertyName);
						const newPercentage = direction.toString();

						// Otherwise transition never gets cleaned up
						if (currentPercentage !== newPercentage)
						{
							handleTransition(newPercentage);
							cube.classList.add('transition');
							setRotationPercentage(parseInt(newPercentage));
						}
					};

				const cubeTransitionEndHandler =
					() =>
					{
						setIdx(index.current);
						isTransitioning.current = false;
						setRotationPercentage(0);
						scene.style.setProperty(RotationPercentageCssPropertyName, '0');
						cubeRef.current.style.removeProperty('transition');
					};

				cube.addEventListener('transitionend', cubeTransitionEndHandler);

				return () =>
				{
					cube.removeEventListener('transitionend', cubeTransitionEndHandler);

					if (!disabled)
					{
						window.removeEventListener('touchstart', touchStartHandler);
						window.removeEventListener('mousedown', touchStartHandler);
						window.removeEventListener('touchmove', touchMoveHandler);
						window.removeEventListener('mousemove', touchMoveHandler);
						window.removeEventListener('touchend', touchEndHandler);
						window.removeEventListener('mouseup', touchEndHandler);
					}
				};
			},
			[disabled, handleTransition, idx, numberOfElements, onChangeIsInteractedWith, setIdx, width],
		);

		const gotoPrevious = useCallback(
			() =>
			{
				if (!isTransitioning.current)
				{
					if (idx === 0)
					{
						setIdx(undefined);
					}
					else
					{
						index.current--;
						handleTransition('1');
					}
				}
			},
			[handleTransition, idx, setIdx],
		);

		const gotoNext = useCallback(
			() =>
			{
				if (!isTransitioning.current)
				{
					if (idx === numberOfElements - 1)
					{
						setIdx(undefined);
					}
					else
					{
						index.current++;
						handleTransition('-1');
					}
				}
			},
			[handleTransition, idx, numberOfElements, setIdx],
		);

		return <div
			ref={sceneRef}
			style={{
				perspective: `${3 * width}px`,
				width: '100%',
				height: '100%',
				cursor: isMouseDown
					? 'grabbing'
					: 'grab',
			}}
		>
			<div
				ref={cubeRef}
				style={{
					position: 'relative',
					width: 'inherit',
					height: 'inherit',
					transformStyle: 'preserve-3d',
					transform: `translateZ(-${width / 2}px) rotateY(calc(var(${RotationPercentageCssPropertyName}) * 90deg))`,
				}}
			>
				{
					new Array(numberOfElements)
						.fill(0)
						.map(
							(_, elementIdx) =>
								<CubeFace
									key={elementIdx}
									render={
										elementIdx >= idx - 1
										&& elementIdx <= idx + 1
									}
									rotation={
										elementIdx <= idx - 1
											? -90
											: elementIdx === idx
												? 0
												: 90
									}
									width={width}
									style={{
										pointerEvents: isRotating || elementIdx !== idx
											? 'none'
											: undefined,
									}}
								>
									{
										renderElement({
											idx: elementIdx,
											onPrevious: gotoPrevious,
											onNext: gotoNext,
										})
									}
								</CubeFace>,
						)
				}
			</div>
		</div>;
	};
