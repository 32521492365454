import { Typography } from '@material-ui/core';
import { FC, useContext } from 'react';
import { TextElement } from '../../../../../../Api/v3/model/story_post/content/element/TextElement';
import { useLocalizedLanguageEntry } from '../../../../../localized_language_entry/language_entry/useLocalizedLanguageEntry';
import { StoryPostHeightProvider } from '../../context/StoryPostHeightProvider';
import { useTextBackgroundElementStyleProps } from './api/useTextBackgroundElementStyleProps';
import { useTextElementStyleProps } from './api/useTextElementStyleProps';

interface TextElementViewerProps
{
	element: TextElement;
}

export const TextElementViewer: FC<TextElementViewerProps> =
	({
		element,
	}) =>
	{
		const postHeight = useContext(StoryPostHeightProvider);

		const localizedText = useLocalizedLanguageEntry(element.text);

		const textBackgroundStyle = useTextBackgroundElementStyleProps(element, postHeight);
		const textStyle = useTextElementStyleProps(element, postHeight);

		return <div
			style={textBackgroundStyle}
		>
			<Typography
				style={textStyle}
			>
				{localizedText}
			</Typography>
		</div>;
	};
